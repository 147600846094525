import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
  useMemo
} from "react";
import DataTable from "react-data-table-component";
import PropTypes from "prop-types";
import orderBy from "lodash/orderBy";

import { Grid } from "@mui/material";
import shortid from "shortid";
import FiltroPesquisaPaginada from "./filtroPesquisaPaginada";
import PaginacaoTabela from "../paginacaoTabela";
import TabelaSemDados from "./tabelaSemDados";
import CarregandoTabela from "./carregandoTabela";

import { customStyles, useStyles, conditionalRowStyles } from "./style";

const TabelaPaginada = forwardRef(
  (
    {
      onChangeFiltrosTabela,
      colunas,
      paginaAtual,
      filtrosAdicionais,
      pesquisar,
      filtroPersonalizado,
      noHeader,
      className,
      noFooter,
      totalItens,
      itemsPorPagina
    },
    ref
  ) => {
    const [linhas, setLinhas] = useState([]);
    const [pagina, setPagina] = useState(paginaAtual || 1);
    const [quantidadeLinhas, setQuantidadeLinhas] = useState(itemsPorPagina);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [filtro, setFiltro] = useState(
      new FiltroPesquisaPaginada(1, itemsPorPagina, null, null, "", {}, {})
    );

    const [loadingQueue, setLoadingQueue] = useState([]);
    const loading = useMemo(() => loadingQueue && loadingQueue.length > 0, [
      loadingQueue
    ]);

    const classe = useStyles();

    const rowsPerPageOptions = [10];

    const passarPagina = (parametros) => {
      if (parametros === pagina) return;

      setPagina(parametros);

      setFiltro((oldState) => {
        if (!oldState)
          return new FiltroPesquisaPaginada(parametros, quantidadeLinhas);

        return new FiltroPesquisaPaginada(
          parametros,
          quantidadeLinhas,
          oldState.colunaOrdenacao,
          oldState.ordenacao,
          oldState.pesquisar,
          oldState.filtrosAdicionais,
          oldState.filtroPersonalizado
        );
      });
    };

    const pesquisarPorString = useCallback(() => {
      // Se pesquisa for por números, então iniciar pesquisa pelo segundo dígito
      const regex = /[0-9]/;
      const checarValor = regex.test(pesquisar);
      let valorPesquisa;
      if (checarValor) {
        valorPesquisa = pesquisar?.length < 2 ?? true ? "" : pesquisar;
      } else {
        valorPesquisa = pesquisar?.length < 3 ?? true ? "" : pesquisar;
      }

      if (valorPesquisa === filtro.pesquisar) return;

      setFiltro(
        (oldState) =>
          new FiltroPesquisaPaginada(
            paginaAtual,
            oldState.totalPagina,
            oldState.colunaOrdenacao,
            oldState.ordenacao,
            valorPesquisa,
            oldState.filtrosAdicionais,
            oldState.filtroPersonalizado
          )
      );
    }, [pesquisar, setFiltro, filtro]);

    const setFiltrosAdicionais = useCallback(() => {
      if (
        JSON.stringify(filtrosAdicionais) ===
        JSON.stringify(filtro?.filtrosAdicionais)
      )
        return;

      setFiltro(
        (oldState) =>
          new FiltroPesquisaPaginada(
            paginaAtual,
            oldState.totalPagina,
            oldState.colunaOrdenacao,
            oldState.ordenacao,
            oldState.pesquisar,
            filtrosAdicionais,
            oldState.filtroPersonalizado
          )
      );
    }, [filtrosAdicionais, setFiltro, filtro]);

    const setFiltroPersonalizado = useCallback(() => {
      if (
        JSON.stringify(filtroPersonalizado) ===
        JSON.stringify(filtro?.filtroPersonalizado)
      )
        return;

      setFiltro(
        (oldState) =>
          new FiltroPesquisaPaginada(
            paginaAtual,
            oldState.totalPagina,
            oldState.colunaOrdenacao,
            oldState.ordenacao,
            oldState.pesquisar,
            oldState.filtrosAdicionais,
            filtroPersonalizado
          )
      );
    }, [filtroPersonalizado, setFiltro, filtro]);

    const ordenarColuna = (coluna, direcao) => {
      setLinhas(orderBy(linhas, coluna?.selector, direcao));
      setPagina(paginaAtual);
    };

    const buscarDadosPaginados = useCallback(async () => {
      const reqId = shortid.generate();

      try {
        setLoadingQueue((oldState) => [...oldState, reqId]);
        const resultado = await onChangeFiltrosTabela(filtro);

        if (!resultado) {
          setLinhas([]);
          setTotalRegistros(0);
          return;
        }

        setLinhas(resultado.linhas);
        setTotalRegistros(resultado.totalItens);
      } catch (error) {
        setLinhas([]);
        setTotalRegistros(0);
      } finally {
        setLoadingQueue((oldState) =>
          oldState.filter((x) => String(x) !== String(reqId))
        );
      }
    }, [filtro]);

    useEffect(() => {
      buscarDadosPaginados();
    }, [buscarDadosPaginados]);

    useEffect(() => {
      setFiltrosAdicionais();
    }, [setFiltrosAdicionais]);

    useEffect(() => {
      setFiltroPersonalizado();
    }, [setFiltroPersonalizado]);

    useEffect(() => {
      pesquisarPorString();
    }, [pesquisarPorString]);

    useImperativeHandle(ref, () => ({
      obterDadosPaginados() {
        buscarDadosPaginados();
      }
    }));

    return (
      <div
        className={className}
        style={{ display: "flex", height: "100%", width: "100%" }}
      >
        <div style={{ flexGrow: 1, overflowX: "scroll" }}>
          <DataTable
            columns={colunas}
            data={linhas}
            noHeader={noHeader}
            page={pagina}
            pagination
            paginationServer
            noRowsPerPage
            sortServer
            progressPending={loading}
            theme="DeltaEnergia"
            customStyles={customStyles}
            conditionalRowStyles={conditionalRowStyles}
            paginationTotalRows={totalRegistros}
            paginationPerPage={quantidadeLinhas}
            progressComponent={
              <CarregandoTabela className={classe.Container} />
            }
            noDataComponent={<TabelaSemDados className={classe.Container} />}
            paginationRowsPerPageOptions={rowsPerPageOptions}
            onChangePage={(parametros) => passarPagina(parametros)}
            onSort={ordenarColuna}
            onChangeRowsPerPage={(linhasPorPagina, pagAtual) => {
              setQuantidadeLinhas(linhasPorPagina);
              setPagina(pagAtual);
            }}
            paginationComponent={(parametros) => {
              return (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ padding: 20 }}
                >
                  {noFooter ? (
                    <PaginacaoTabela
                      totalRegistros={parametros.rowCount}
                      onChangePagina={parametros.onChangePage}
                      pagina={parametros.currentPage}
                      registrosPorPagina={parametros.rowsPerPage}
                      totalItens={totalItens}
                    />
                  ) : null}
                </Grid>
              );
            }}
          />
        </div>
      </div>
    );
  }
);

TabelaPaginada.propTypes = {
  onChangeFiltrosTabela: PropTypes.func.isRequired,
  colunas: PropTypes.oneOfType([PropTypes.array]).isRequired,
  paginaAtual: PropTypes.number.isRequired,
  pesquisar: PropTypes.string,
  filtrosAdicionais: PropTypes.oneOfType([PropTypes.any]),
  filtroPersonalizado: PropTypes.oneOfType([PropTypes.any]),
  noHeader: PropTypes.bool,
  className: PropTypes.string,
  noFooter: PropTypes.bool,
  totalItens: PropTypes.bool,
  itemsPorPagina: PropTypes.number
};

TabelaPaginada.defaultProps = {
  pesquisar: "",
  filtrosAdicionais: {},
  filtroPersonalizado: {},
  noFooter: true,
  noHeader: false,
  className: "",
  totalItens: false,
  itemsPorPagina: 10
};

export default TabelaPaginada;
