import { makeStyles } from "@mui/styles";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  formControl: {
    fontFamily: theme.font.family,
    "& .MuiFormLabel-root": {
      color: ({ styleForm }) =>
        styleForm ? "none" : `${theme.color.selectArredondadoColor} !important`
    }
  },
  select: {
    borderRadius: "4px",
    fontFamily: theme.font.family,
    marginBottom: (props) => props.marginBottom,
    maxHeight: (props) => props.maxHeight,
    maxWidth: (props) => props.maxWidth,
    "& .MuiOutlinedInput-root": {
      fontFamily: theme.font.family,
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        fontFamily: theme.font.family
      },
      "& .MuiOutlinedInput-notchedOutline": {
        fontFamily: theme.font.family
      }
    },
    // "& .MuiOutlinedInput-input": {
    //   padding: "13.5px 14px"
    // },
    "& label.Mui-focused": {
      color: ({ styleForm }) =>
        styleForm ? "none" : `${theme.color.selectArredondadoColor} !important`
    },
    "& .MuiInput-underline:after": {
      borderColor: ({ styleForm }) =>
        styleForm ? "none" : `${theme.color.selectArredondadoColor} !important`
    },
    "& .MuiFormLabel-root": {
      color: ({ styleForm }) =>
        styleForm ? "none" : `${theme.color.selectArredondadoColor} !important`
    },
    // eslint-disable-next-line no-dupe-keys
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: ({ styleForm }) =>
          styleForm
            ? "none"
            : `${theme.color.selectArredondadoColor} !important`
      },
      "& .MuiInputAdornment-root": {
        color: ({ styleForm }) =>
          styleForm
            ? "none"
            : `${theme.color.selectArredondadoColor} !important`,
        "& .MuiButtonBase-root": {
          color: ({ styleForm }) =>
            styleForm
              ? "none"
              : `${theme.color.selectArredondadoColor} !important`
        }
      },
      "& fieldset": {
        borderRadius: theme.borda.input,
        borderColor: ({ styleForm }) =>
          styleForm
            ? "none"
            : `${theme.color.selectArredondadoColor} !important`
      },
      "&:hover fieldset": {
        borderColor: ({ styleForm }) =>
          styleForm
            ? "none"
            : `${theme.color.selectArredondadoColor} !important`
      },
      "&.Mui-focused fieldset": {
        borderColor: ({ styleForm }) =>
          styleForm
            ? "none"
            : `${theme.color.selectArredondadoColor} !important`
      }
    },
    "& .MuiSelect-icon": {
      color: ({ styleForm }) =>
        styleForm ? "none" : `${theme.color.selectArredondadoColor} !important`
    },
    "& .MuiIconButton-label": {
      color: ({ styleForm }) =>
        styleForm ? "none" : `${theme.color.selectArredondadoColor} !important`
    }
  },
  selectIcon: {
    color: theme.color.borderColor,
    marginRight: "4px",
    position: "absolute",
    right: 0,
    top: "calc(50% - 12px)",
    "pointer-events": "none"
  },
  menuIcon: {
    marginRight: "10px"
  },
  adornment: {
    marginRight: "10px"
  }
}));

export const customStyles = makeStyles(() => ({
  formControl: {
    fontFamily: theme.font.family
  },
  label: {
    fontFamily: theme.font.family,
    fontSize: (props) => props.isFuncionality && "18px",
    margin: 0,
    pointerEvents: "none",
    transform: (props) =>
      !props.isFuncionality && "translate(15px, 6px) scale(1)",
    zIndex: 1,
    "&.MuiInputLabel-shrink": {
      display: "none"
    }
  },
  select: {
    fontFamily: theme.font.family,
    marginBottom: (props) => props.marginBottom,
    maxHeight: (props) => props.maxHeight,
    maxWidth: (props) => props.maxWidth,
    "& .MuiOutlinedInput-root": {
      borderColor: theme.color.borderColor,
      borderRadius: "50rem",
      fontFamily: theme.font.family,
      maxHeight: "30px",
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        fontFamily: theme.font.family,
        padding: "10px",
        textAlign: "left",
        transform: (props) => props.isFuncionality && "translateY(12px)"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.color.borderColor} !important`,
        fontFamily: theme.font.family
      }
    },
    // "& .MuiOutlinedInput-input": {
    //   padding: "13.5px 14px"
    // },
    "& label.Mui-focused": {
      color: ({ styleForm }) =>
        styleForm ? "none" : `${theme.color.selectArredondadoColor} !important`
    },
    "& .MuiInput-underline:after": {
      borderColor: ({ styleForm }) =>
        styleForm ? "none" : `${theme.color.selectArredondadoColor} !important`
    },
    "& .MuiFormLabel-root": {
      color: ({ styleForm }) =>
        styleForm ? "none" : `${theme.color.selectArredondadoColor} !important`
    },
    // eslint-disable-next-line no-dupe-keys
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: ({ styleForm }) =>
          styleForm
            ? "none"
            : `${theme.color.selectArredondadoColor} !important`
      },
      "& .MuiInputAdornment-root": {
        color: ({ styleForm }) =>
          styleForm
            ? "none"
            : `${theme.color.selectArredondadoColor} !important`,
        "& .MuiButtonBase-root": {
          color: ({ styleForm }) =>
            styleForm
              ? "none"
              : `${theme.color.selectArredondadoColor} !important`
        }
      },
      "& fieldset": {
        borderColor: ({ styleForm }) =>
          styleForm
            ? "none"
            : `${theme.color.selectArredondadoColor} !important`
      },
      "&:hover fieldset": {
        borderColor: ({ styleForm }) =>
          styleForm
            ? "none"
            : `${theme.color.selectArredondadoColor} !important`
      },
      "&.Mui-focused fieldset": {
        borderColor: ({ styleForm }) =>
          styleForm
            ? "none"
            : `${theme.color.selectArredondadoColor} !important`
      }
    },
    "& .MuiSelect-icon": {
      color: ({ styleForm }) =>
        styleForm ? "none" : `${theme.color.selectArredondadoColor} !important`
    },
    "& .MuiIconButton-label": {
      color: ({ styleForm }) =>
        styleForm ? "none" : `${theme.color.selectArredondadoColor} !important`
    }
  },
  selectIcon: {
    color: `${theme.color.borderColor} !important`,
    marginRight: (props) => (!props.isFuncionality ? "4px" : "15px"),
    position: "absolute",
    right: 0,
    top: (props) => (!props.isFuncionality ? "calc(50% - 12px)" : "50%"),
    "pointer-events": "none"
  },
  menuIcon: {
    marginRight: "10px"
  }
}));
