import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import { Grid, Tooltip } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import HelpIcon from "@mui/icons-material/Help";

import { Controller, useForm } from "react-hook-form";

import { useStyles } from "./style";

const MaterialInputBusca = React.forwardRef((props, ref) => {
  const {
    label,
    id,
    name,
    type,
    defaultValue,
    permiteValorBranco,
    renderIconShowHide,
    errors,
    style,
    className,
    searchAdornment,
    onBlur,
    onInput,
    helperLabel,
    maxLength,
    disabled
  } = props;

  const { control, setValue } = useForm();
  const [typeInput, setTypeInput] = useState(type);

  useMemo(() => {
    if (defaultValue || permiteValorBranco) setValue(`${name}`, defaultValue);
  }, [defaultValue, setValue, name]);

  const onClickShowPassword = () => {
    setTypeInput(typeInput === "text" ? "password" : "text");
  };

  const onMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const showAdornment = (show) => {
    if (searchAdornment) {
      return {
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      };
    }
    if (show) {
      return {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={onClickShowPassword}
              onMouseDown={onMouseDownPassword}
              edge="end"
              size="large"
            >
              {typeInput === "text" ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      };
    }
    return null;
  };

  const classes = useStyles();

  return (
    <Grid container spacing={0} alignItems="flex-end">
      <Grid item lg={helperLabel ? 11 : 12} xs={helperLabel ? 11 : 12}>
        <Controller
          as={TextField}
          control={control}
          onBlur={onBlur}
          onInput={onInput}
          id={id}
          name={name}
          variant="outlined"
          label={label}
          type={typeInput}
          inputProps={{
            maxLength
          }}
          InputProps={{
            ...showAdornment(renderIconShowHide),
            onBlur
          }}
          defaultValue={defaultValue}
          classes={{ root: classes.root }}
          className={className}
          inputRef={ref}
          error={Boolean(errors[name]?.message)}
          helperText={errors[name]?.message ?? ""}
          fullWidth
          style={style}
          autoComplete="off"
          disabled={disabled}
        />
      </Grid>
      {helperLabel ? (
        <Grid item lg={1}>
          <Tooltip title={helperLabel} aria-label={helperLabel}>
            <HelpIcon />
          </Tooltip>
        </Grid>
      ) : null}
    </Grid>
  );
});

MaterialInputBusca.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  renderIconShowHide: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.object]),
  style: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.oneOfType([PropTypes.any]),
  searchAdornment: PropTypes.bool,
  onBlur: PropTypes.oneOfType([PropTypes.func]),
  onInput: PropTypes.oneOfType([PropTypes.func]),
  helperLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  permiteValorBranco: PropTypes.bool
};

MaterialInputBusca.defaultProps = {
  label: "",
  id: "",
  type: "text",
  defaultValue: "",
  renderIconShowHide: false,
  errors: {},
  style: {},
  className: undefined,
  searchAdornment: false,
  onBlur: () => {},
  onInput: () => {},
  helperLabel: "",
  maxLength: "",
  disabled: false,
  permiteValorBranco: false
};

export default MaterialInputBusca;
