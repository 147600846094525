import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { Redirect } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";

// Componentes
import { CssBaseline } from "@mui/material";
import Cabecalho from "../../componentes/cabecalho";
import Componente from "../../componentes/conteudo/componente";
import Menu from "../../componentes/menu";

// Styles
import { useStyles } from "../../componentes/conteudo/style";

import { AtualizaNotificacoesVisualizadas } from "../../servicos/notificacaoServico";

const RotaAutenticada = ({ rota }) => {
  const classes = useStyles({
    hideHeader: ["Demo", "Home"].includes(rota?.caminho) || rota?.esconderMenu
  });
  const usuario = useSelector((state) => state.usuario);
  const { menu } = usuario;

  const [urlCallback, setUrlCallback] = useState(
    localStorage.getItem("url_retorno")
  );

  const urlPath = urlCallback || "/";

  const obterFuncionalidade = (caminho) => {
    return menu.find((item) => {
      const segmento = item.functionality.funcionalidadeColecao.find(
        (funcionalidade) => funcionalidade.caminho === caminho
      );

      if (segmento) return segmento;

      return item.functionality.funcionalidadeColecao.find(
        (funcionalidade) =>
          funcionalidade.funcionalidadeColecao.findIndex(
            (seg) => seg.caminho === caminho
          ) >= 0
      );
    });
  };

  const temPermissaoAcessoRota = useMemo(() => {
    let urlPathSplit = "";

    if (rota.path !== "/" && urlCallback) {
      const URLsplit = urlCallback.split("/");
      const host = `${URLsplit[0]}//${URLsplit[2]}/`;
      const newURL = urlCallback.replace(host, "");
      // eslint-disable-next-line prefer-destructuring
      urlPathSplit = newURL.split("?")[0];
      setUrlCallback(urlPathSplit);
    }

    if (rota.path === "/" || rota.path === `/${urlPathSplit}`) {
      localStorage.removeItem("url_retorno");
      return true;
    }

    if (
      (rota.path === "/fluxo-migracao/migracao-gd" &&
        usuario.usuario.perfilId === 43) ||
      (rota.path === "/dashboard/economia" && usuario.usuario.perfilId === 46)
    ) {
      return true;
    }

    const funcionalidade = obterFuncionalidade(rota.caminho);

    return !!funcionalidade;
  }, [rota, menu]);

  if (
    usuario?.token &&
    rota.autenticada &&
    rota.estruturada &&
    temPermissaoAcessoRota
  ) {
    return (
      <div className={classes.root}>
        <CssBaseline />
        <Cabecalho
          atualizaNotificacoesVisualizadas={AtualizaNotificacoesVisualizadas}
        />
        <Menu role={usuario?.usuario?.role} />
        <main className={classes.content}>
          <Cabecalho invisible />
          <div className={classes.toolbar} />
          <Componente rota={rota} />
        </main>
      </div>
    );
  }

  return <Redirect to={urlPath} />;
};

RotaAutenticada.propTypes = {
  rota: PropTypes.oneOfType([PropTypes.any]).isRequired
};

export default RotaAutenticada;
