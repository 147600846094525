import { makeStyles } from "@mui/styles";
import DataTable, { createTheme } from "react-data-table-component";
import styled from "styled-components";

import theme from "themes";
import { Base } from "../cores";

export const DataTables = styled(DataTable)`
  .rdt_TableHead {
    display: none !important;
  }
  .rdt_TableCell {
    padding: 0 !important;
  }
  .rdt_TableRow {
    border: none !important;
  }
`;

export const useStyles = makeStyles({
  Container: {
    background: theme.color.red,
    height: "300px",
    marginTop: "2em",
    marginBottom: "2em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  checkBoxBase: {
    "&:checked": {
      color: "black"
    }
  },
  iconeSelecionado: {
    fontWeight: "bold",
    color: "black",
    backgroundColor: `${theme.color.checkButonColor}`
  },
  iconeSemSelecao: {
    color: theme.color.bordaCheckbox,
    backgroundColor: theme.color.fundoCheckbox,
    borderRadius: "2px"
  }
});

export const conditionalRowStyles = [
  {
    when: (row) => !row.ativo,
    style: {
      "& div": {}
    }
  }
];

export const customStyles = {
  table: {
    style: {
      background: "transparent"
    }
  },
  headRow: {
    style: {
      background: "transparent"
    }
  },
  headCells: {
    style: {
      background: theme.color.bgTabela,
      marginLeft: "3px",
      fontSize: "16px",
      "& div": {
        color: `${theme.color.secondaryBorderColor} !important`,
        "&:hover": {
          color: `${theme.color.secondaryBorderColor} !important`
        }
      }
    }
  },
  rows: {
    style: {
      background: "transparent",
      marginBottom: "1px",
      marginTop: "1px"
    }
  },
  Pagination: {
    styles: {
      color: `${theme.color.secondaryBorderColor} !important`
    }
  },
  cells: {
    style: {
      color: `${theme.color.secondaryBorderColor} !important`,
      marginLeft: "3px",
      background: theme.color.bgTabela,
      fontSize: "14px",
      "& .MuiButtonBase-root": {
        color: `${theme.color.secondaryBorderColor} !important`
      }
    }
  }
};

export const customStylesMobile = {
  tableWrapper: {
    style: {
      background: "transparent",
      display: "inherit"
    }
  },
  table: {
    style: {
      background: "transparent",
      display: "inherit"
    }
  },
  headCells: {
    style: {
      background: "#2a2b32",
      marginLeft: "3px",
      fontSize: "16px",
      "& div": {
        "&:hover": {
          color: `${Base.OffWhite} !important`
        }
      }
    }
  },
  tableBody: {
    style: {
      background: "transparent"
    }
  },
  rows: {
    style: {
      background: "transparent",
      marginBottom: "1px",
      marginTop: "1px"
    }
  },
  Pagination: {
    styles: {
      color: "#eef0f9"
    }
  },
  cells: {
    style: {
      marginLeft: 0,
      background: "transparent",
      fontSize: "14px"
    }
  }
};
createTheme({
  background: {
    default: "transparent"
  },
  text: {
    primary: theme.color.secondaryBorderColor,
    secondary: theme.color.secondaryBorderColor
  },
  action: {
    hover: theme.color.secondaryBorderColor
  }
});
