import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

import { usuarioPossuiFuncionalidade } from "../../../../../../../servicos/funcionalidadesServico";
import { RotasDTO } from "../../../../../../../global/rotas/rotasUrlDto";
import { InterfaceDTO } from "../../../../../../../global/dto/interfacesDto";
import CheckInputs from "../../../../componentes/CheckInputs";
import MaterialInputTexto from "../../../../../../../componentes/inputTexto/materialInput";
import MaterialInputTelefone from "../../../../../../../componentes/inputTexto/materialInputTelefone";
import MaterialInputMascara from "../../../../../../../componentes/inputTextoMascara";

import { useStyles } from "./style";
import { validarCpfCnpj } from "../../../../../../../global/utils/formatCpfCnpj";
import { existeAcento } from "../../../../../../../servicos/utils";

const InformacaoUsuario = ({
  nomeContratante,
  emailContratante,
  telefoneContratante,
  register,
  errors,
  cpfCliente,
  enviarForm,
  editarInputs,
  statusVigenciaFechado,
  enviarFormProposta
}) => {
  const classes = useStyles();
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cpf, setCPF] = useState("");
  const [checked, setChecked] = useState(false);
  const usuario = useSelector((state) => state.usuario);
  const rotas = useSelector((state) => state.rotas);
  const { menu } = usuario;

  const podeEditarCpfCnpj = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.CotacoesGD,
    InterfaceDTO.PodeEditarCPFCNPJ
  );

  const habiltarEditar = useMemo(() => {
    return podeEditarCpfCnpj && checked;
  }, [podeEditarCpfCnpj, checked]);

  useEffect(() => {
    setNomeCompleto(nomeContratante);
    setEmail(emailContratante);
    setTelefone(telefoneContratante);
  }, [nomeContratante, emailContratante, telefoneContratante]);

  useEffect(() => {
    setCPF(cpfCliente);
  }, [cpfCliente]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <MaterialInputTexto
            type="text"
            id="nomeCompleto"
            name="nomeCompleto"
            label="Nome completo"
            defaultValue={nomeCompleto ?? ""}
            permiteValorBranco
            renderIconShowHide={false}
            ref={register({
              required: !nomeCompleto
                ? "O campo Nome Completo é obrigatório!"
                : false,
              maxLength: {
                value: 100,
                message: "Quantidade máxima de 100 caracteres!"
              }
            })}
            errors={errors}
            allowClear
            className={
              !editarInputs || statusVigenciaFechado
                ? classes.desabledInput
                : ""
            }
            readOnly={!editarInputs || statusVigenciaFechado}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <MaterialInputTexto
            type="text"
            id="email"
            name="email"
            label="E-mail"
            defaultValue={email ?? ""}
            permiteValorBranco
            renderIconShowHide={false}
            ref={register({
              required:
                enviarFormProposta || enviarForm
                  ? "O campo E-mail é obrigatório!"
                  : false,
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Campo E-mail inválido!"
              },
              maxLength: {
                value: 100,
                message: "Quantidade máxima de 100 caracteres!"
              },
              validate: (value) =>
                !existeAcento(value) || "Campo E-mail inválido!"
            })}
            errors={errors}
            className={statusVigenciaFechado ? classes.desabledInput : ""}
            readOnly={statusVigenciaFechado}
          />
        </Grid>
        {podeEditarCpfCnpj && !statusVigenciaFechado && (
          <Grid item xs={12}>
            <CheckInputs setChecked={setChecked} checked={checked} />
          </Grid>
        )}
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <MaterialInputMascara
            type="text"
            id="cpf"
            name="cpf"
            label="CPF"
            mask="###.###.###-##"
            renderIconShowHide={false}
            defaultValue={cpf ?? ""}
            ref={register({
              required: "O campo CPF é obrigatório!",
              maxLength: {
                value: 14,
                message: "Quantidade máxima de 14 caracteres!"
              },
              validate: (value) =>
                value ? validarCpfCnpj(value) || "CPF inválido!" : true
            })}
            errors={errors}
            className={
              !habiltarEditar || statusVigenciaFechado
                ? classes.desabledInput
                : ""
            }
            readOnly={!habiltarEditar || statusVigenciaFechado}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={12}>
          <MaterialInputTelefone
            type="text"
            id="telefone"
            name="telefone"
            label="Telefone"
            defaultValue={telefone ?? ""}
            permiteValorBranco
            renderIconShowHide={false}
            ref={register({
              required:
                enviarFormProposta || enviarForm
                  ? "O campo Telefone é obrigatório!"
                  : false,
              minLength: {
                value: 18,
                message: "Quantidade mínima de 12 caracteres!"
              }
            })}
            errors={errors}
            className={statusVigenciaFechado ? classes.desabledInput : ""}
            readOnly={statusVigenciaFechado}
          />
        </Grid>
      </Grid>
    </>
  );
};

InformacaoUsuario.propTypes = {
  nomeContratante: PropTypes.string,
  emailContratante: PropTypes.string,
  telefoneContratante: PropTypes.string,
  register: PropTypes.oneOfType([PropTypes.func]),
  errors: PropTypes.string,
  cpfCliente: PropTypes.string,
  enviarForm: PropTypes.bool,
  editarInputs: PropTypes.bool,
  statusVigenciaFechado: PropTypes.bool,
  enviarFormProposta: PropTypes.bool
};
InformacaoUsuario.defaultProps = {
  nomeContratante: "",
  emailContratante: "",
  telefoneContratante: "",
  register: () => {},
  errors: "",
  cpfCliente: "",
  enviarForm: true,
  editarInputs: false,
  statusVigenciaFechado: false,
  enviarFormProposta: false
};

export default InformacaoUsuario;
