import React, { useEffect, useRef, useState } from "react";

// Icones
import TuneIcon from "@mui/icons-material/Tune";
import IconeExcel from "assets/icones/operacoes/excel.png";

// Componentes
import { Grid } from "@mui/material";
import { Loader } from "componentes";
import Botao from "componentes/botao";
import BotaoFiltroOrdenacao from "componentes/botaoFiltroOrdenar";
import MaterialInputBusca from "componentes/inputBusca";
import MaterialInputMascara from "componentes/inputTextoMascara";
import SelectArredondado from "componentes/selectArredondado";
import TabelaPaginada from "componentes/tabelaPaginada";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import ResultadoPaginadoDto from "componentes/tabelaPaginada/resultadoPaginadoDto";

// Redux
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { desabilitarUnidade } from "global/redux/modulos/usuario/actions";

// Útils
import {
  ordenacaoPorColuna,
  listarFiltrarPorCriacaoDeSenha,
  listarCancelado,
  listarRejeitado
} from "../../../servicos/listagemOperacaoControleServico";
import FiltroAdicionalTabela from "./filtroAdicionalTabela";
import OperacaoDto from "./operacaoDto";
import ColunaDocumentos from "./colunaCustomizada/colunaDocumentos";
import ColunaOpcoes from "./colunaCustomizada/colunaOpcoes";
import ListagemOperacoesHelper from "./helper";

// Styles
import theme from "../../../themes";
import { BotaoDownload, Icone, useStyles } from "./style";

const ListagemOperacoes = () => {
  const classes = useStyles();
  const refTabela = useRef(null);

  const [carregandoOperacoes, setCarregandoOperacoes] = useState();
  const [campoPesquisa, setCampoPesquisa] = useState("cliente");
  const [ordemColunaSelecionada, setOrdemColunaSelecionada] = useState();
  const [valorOrdenacao, setValorOrdenacao] = useState(0);
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroAdicionalTabela()
  );
  const [filtroCancelado, setFiltroCancelado] = useState("");
  const [filtroRejeitado, setFiltroRejeitado] = useState("");

  const statusDocumento = [
    { id: 1, valor: "Não Iniciado" },
    { id: 2, valor: "Requer Validação" },
    { id: 3, valor: "Atrasado" },
    { id: 4, valor: "Com Problema" },
    { id: 5, valor: "Finalizado" },
    { id: 6, valor: "Validação Jurídico" }
  ];

  const status = [
    { id: 1, valor: "Não Iniciado" },
    { id: 2, valor: "Em Andamento" },
    { id: 3, valor: "Atrasado" },
    { id: 4, valor: "Com Problema" },
    { id: 5, valor: "Finalizado" }
  ];

  const statusTroca = [
    { id: 1, valor: "Não Iniciado" },
    { id: 2, valor: "Em Andamento" },
    { id: 3, valor: "Atrasado" },
    { id: 4, valor: "Com Problema" },
    { id: 5, valor: "Finalizado" },
    { id: 6, valor: "Requer Associação na UC" }
  ];

  const [documentos, setDocumentos] = useState([]);
  const [trocaDeTitularidade, setTrocaDeTitularidade] = useState([]);
  const [medidor, setMedidor] = useState([]);
  const [finalizado, setFinalizado] = useState([]);
  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [temDados, setTemDados] = useState(true);
  const [textoBusca, setTextoBusca] = useState("");
  const [distribuidoraId, setDistribuidoraId] = useState();
  const [distribuidoraLista, setDistribuidoraLista] = useState();
  const [mascaraCpfCnpj, setMascaraCpfCnpj] = useState("###.###.###-##");
  const [labelTextoBusca, setLabelTextoBusca] = useState("");
  const [
    filtroSelecionadoClienteComSenha,
    setFiltroSelecionadoClienteComSenha
  ] = useState("");

  const handleAlterarFiltroLateral = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        valorOrdenacao,
        textoBusca,
        documentos,
        trocaDeTitularidade,
        medidor,
        finalizado,
        distribuidoraId,
        filtroSelecionadoClienteComSenha
      )
    );
  };

  const alterarFiltroDocumentos = (valor) => {
    setDocumentos(valor);
    handleAlterarFiltroLateral();
  };

  const alterarFiltroTrocaDeTitularidade = (valor) => {
    setTrocaDeTitularidade(valor);
    handleAlterarFiltroLateral();
  };

  const alterarMedidor = (valor) => {
    setMedidor(valor);
    handleAlterarFiltroLateral();
  };

  const alterarFiltroFinalizado = (valor) => {
    setFinalizado(valor);
    handleAlterarFiltroLateral();
  };

  const obterDistribuidoras = async () => {
    const lista = await ListagemOperacoesHelper.listarDistribuidoraGD();

    setDistribuidoraLista([
      {
        id: 0,
        nomeFantasia: "Todos"
      },
      ...lista?.data
    ]);
  };

  const colunas = [
    new Coluna("clienteNome", "Cliente"),
    new Coluna("unidadeNome", "Unidade"),
    new Coluna("distribuidora", "Distribuidora"),
    new ColunaDocumentos(
      "Documentos",
      "statusDocumento",
      statusDocumento,
      documentos,
      alterarFiltroDocumentos
    ),
    new ColunaDocumentos(
      "Troca de Titularidade",
      "statusTrocaDeTitularidade",
      statusTroca,
      trocaDeTitularidade,
      alterarFiltroTrocaDeTitularidade
    ),
    new ColunaDocumentos(
      "Medidor",
      "statusMedidor",
      status,
      medidor,
      alterarMedidor
    ),
    new ColunaDocumentos(
      "Finalizado",
      "statusFinalizado",
      status,
      finalizado,
      alterarFiltroFinalizado
    ),
    new ColunaComponentePersonalizado(
      "operacaoPassoOnboardingId",
      "Opções",
      ColunaOpcoes
    )
  ];

  const obterOperacoes = async (parametros) => {
    setCarregandoOperacoes(true);
    const parametrosBusca = parametros;

    let parametrosFiltro = "";
    if (documentos.length) {
      documentos.map((doc) => {
        parametrosFiltro += `&documentos=${doc}`;
        return doc;
      });
    }
    if (trocaDeTitularidade.length) {
      trocaDeTitularidade.map((troca) => {
        parametrosFiltro += `&trocaDeTitularidade=${troca}`;
        return troca;
      });
    }
    if (medidor.length) {
      medidor.map((med) => {
        parametrosFiltro += `&medidor=${med}`;
        return med;
      });
    }
    if (finalizado.length) {
      finalizado.map((fin) => {
        parametrosFiltro += `&finalizado=${fin}`;
        return fin;
      });
    }
    if (campoPesquisa && textoBusca) {
      if (campoPesquisa === "cnpj" || campoPesquisa === "cpf")
        parametrosBusca.cnpjCpf = textoBusca;
      else parametrosBusca[campoPesquisa] = textoBusca;
    }
    if (distribuidoraId) parametrosBusca.distribuidoraId = distribuidoraId;

    const resultado = await ListagemOperacoesHelper.listarOperacaoControlePaginado(
      parametrosBusca,
      parametrosFiltro
    );

    setCarregandoOperacoes(false);

    if (!resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );

      setTemDados(false);
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (resultado?.length === 0 ?? true) {
      setTemDados(false);
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    const resLinhas = await resultado.data?.lista?.map((res) => {
      return new OperacaoDto(
        res.id,
        res.clienteId,
        res.clienteNome,
        res.unidadeId,
        res.unidadeNome,
        res.statusDocumento,
        res.statusTrocaDeTitularidade,
        res.statusMedidor,
        res.statusFinalizado,
        res.distribuidoraNome
      );
    });

    if (!resLinhas) setTemDados(false);
    else setTemDados(true);

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado.data.paginaAtual,
      resultado.data.totalItens,
      resultado.data.totalPaginas
    );
  };

  const onChangeFiltrosTabela = async (parametros) => {
    return obterOperacoes({
      tamanhoPaginacao: parametros.totalPagina,
      pagina: parametros.pagina,
      ordem: valorOrdenacao,
      clienteComSenha: filtroSelecionadoClienteComSenha,
      cancelado: filtroCancelado,
      rejeitado: filtroRejeitado || "false"
    });
  };

  const handleClickFiltroSelecionadoClienteComSenha = (event) => {
    const val = filtroSelecionadoClienteComSenha === event ? "" : event;
    setFiltroSelecionadoClienteComSenha(val);
  };

  const handleClickSortOrderSelecionado = (valor, idCampoSelecionado) => {
    const val =
      ordemColunaSelecionada === idCampoSelecionado && valor === ""
        ? ""
        : idCampoSelecionado;
    setOrdemColunaSelecionada(val);
    setValorOrdenacao(valor);
  };

  const handleClickAtivarFiltro = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        valorOrdenacao,
        textoBusca,
        null,
        null,
        null,
        null,
        distribuidoraId,
        filtroSelecionadoClienteComSenha,
        filtroCancelado,
        filtroRejeitado
      )
    );
  };

  const onClickBuscar = () => {
    if (textoBusca || distribuidoraId) {
      handleClickAtivarFiltro();
    }
  };

  const onClickLimpar = () => {
    setCampoPesquisa("cliente");
    setTextoBusca("");
    setDocumentos([]);
    setTrocaDeTitularidade([]);
    setMedidor([]);
    setFinalizado([]);
    setOrdemSelecionada([]);
    setValorOrdenacao(0);
    setOrdemColunaSelecionada();
    setFiltroAdicionalTabela(new FiltroAdicionalTabela(0, ""));
    setDistribuidoraId();
    setFiltroSelecionadoClienteComSenha("");
  };

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroCancelado = (event) => {
    const val = filtroCancelado === event ? "" : event;
    setFiltroCancelado(val);
  };

  const handleClickFiltroRejeitado = (event) => {
    const val = filtroRejeitado === event ? "" : event;
    setFiltroRejeitado(val);
  };

  const downloadExcel = async () => {
    setCarregandoOperacoes(true);
    await ListagemOperacoesHelper.exportarExcel();
    setCarregandoOperacoes(false);
  };

  useEffect(() => {
    store.dispatch(desabilitarUnidade(true));

    return () => store.dispatch(desabilitarUnidade(false));
  }, [desabilitarUnidade]);

  useEffect(() => {
    obterDistribuidoras();
  }, []);

  useEffect(() => {
    setTextoBusca("");

    if (campoPesquisa === "cpf") {
      setLabelTextoBusca("Pesquisar por CPF");
      setMascaraCpfCnpj("###.###.###-##");
    } else if (campoPesquisa === "cnpj") {
      setLabelTextoBusca("Pesquisar por CNPJ");
      setMascaraCpfCnpj("##.###.###/####-##");
    } else if (campoPesquisa === "numeroInstalacao") {
      setLabelTextoBusca("Pesquisar por número de instalação");
      setMascaraCpfCnpj(null);
    } else if (campoPesquisa === "cliente") {
      setLabelTextoBusca("Pesquisar por nome do cliente");
    } else if (campoPesquisa === "unidade") {
      setLabelTextoBusca("Pesquisar por nome da unidade");
    }
  }, [campoPesquisa]);

  return (
    <Loader loading={carregandoOperacoes} className="w-auto">
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
        className={classes.container}
      >
        <Grid container item spacing={2} lg={9} md={8} xs={12}>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <SelectArredondado
              select
              id="CampoPesquisa"
              name="CampoPesquisa"
              label="Filtrar por campo"
              placeholder="Filtrar por campo"
              valueKey="valor"
              valueName="nome"
              className={classes.select}
              dataSource={[
                { nome: "Nome do Cliente", valor: "cliente" },
                { nome: "Nome da Unidade", valor: "unidade" },
                { nome: "CPF da Unidade", valor: "cpf" },
                { nome: "CNPJ da Unidade", valor: "cnpj" },
                { nome: "Número de Instalação", valor: "numeroInstalacao" }
              ]}
              value={campoPesquisa}
              onChange={(e) => {
                setCampoPesquisa(e.target.value);
              }}
              permiteValorBranco
              styleForm={false}
            />
          </Grid>
          {campoPesquisa === "cliente" ||
          campoPesquisa === "unidade" ||
          campoPesquisa === "numeroInstalacao" ? (
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <MaterialInputBusca
                type="text"
                id="textoBusca"
                name="textoBusca"
                label={labelTextoBusca}
                className={classes.search}
                renderIconShowHide
                searchAdornment
                permiteValorBranco
                defaultValue={textoBusca}
                onInput={(e) => setTextoBusca(e.target.value)}
              />
            </Grid>
          ) : (
            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="font-weight-bold"
            >
              <MaterialInputMascara
                type="text"
                id="textoBusca"
                name="textoBusca"
                label={labelTextoBusca}
                mask={mascaraCpfCnpj}
                value={textoBusca}
                renderIconShowHide={false}
                className={classes.search}
                onInputChange={(valor) => setTextoBusca(valor)}
              />
            </Grid>
          )}
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <SelectArredondado
              select
              id="Distribuidora"
              name="Distribuidora"
              label="Distribuidora"
              placeholder="Distribuidora"
              valueKey="id"
              valueName="nomeFantasia"
              className={classes.select}
              dataSource={distribuidoraLista}
              value={distribuidoraId}
              onChange={(e) => {
                setDistribuidoraId(e.target.value);
              }}
              permiteValorBranco
              styleForm={false}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={6} xs={12}>
            <Botao
              label={temDados ? "Buscar" : "Limpar filtro"}
              onClick={() =>
                temDados ? onClickBuscar() : onClickLimpar(temDados)
              }
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2} lg={3} md={4} xs={12}>
          <Grid item lg={9} md={9} sm={6} xs={9}>
            <BotaoFiltroOrdenacao
              type="button"
              color={theme.color.secondaryBorderColor}
              background="transparent"
              label="Filtrar / Ordernar"
              icon={<TuneIcon />}
              className={classes.buttonFiltro}
              ordenacaoColuna={ordenacaoPorColuna()}
              ordemColunaSelecionada={ordemColunaSelecionada}
              ordemSelecionada={ordemSelecionada}
              ordenadorDois="Cliente criou senha"
              ordenadorTres="Cancelado"
              ordenadorSeis="Rejeitado"
              filtros={listarFiltrarPorCriacaoDeSenha()}
              filtrosSelecionados={filtroSelecionadoClienteComSenha}
              onClickFiltro={handleClickFiltroSelecionadoClienteComSenha}
              onClickSortOrder={handleClickSortOrderSelecionado}
              onClickOrdenacao={handleClickOrdemSelecionada}
              onClickAtivarAgora={handleClickAtivarFiltro}
              filtroTipoCliente={listarCancelado()}
              filtroStatus={listarRejeitado()}
              filtrosTipoClienteSelecionado={filtroCancelado}
              filtroStatusSelecionados={filtroRejeitado}
              onClickFitroTipoCliente={handleClickFiltroCancelado}
              onClickFiltroStatus={handleClickFiltroRejeitado}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={3}>
            <BotaoDownload onClick={downloadExcel}>
              <Icone src={IconeExcel} alt="Ícone excel" />
            </BotaoDownload>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mt-4">
          <TabelaPaginada
            ref={refTabela}
            onChangeFiltrosTabela={onChangeFiltrosTabela}
            colunas={colunas}
            filtrosAdicionais={filtroAdicionalTabela}
            paginationPerPage={10}
            paginaAtual={1}
            qtdLinhas={3}
            noHeader
            totalItens
          />
        </Grid>
      </Grid>
    </Loader>
  );
};

export default ListagemOperacoes;
