import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

// Componentes
import LaunchIcon from "@mui/icons-material/Launch";
import { Box, Card, Chip, Grid } from "@mui/material";
import { ReactComponent as Upload } from "assets/cotacoesGd/upload.svg";
import IconeAprovar from "assets/icones/operacoes/aprovar.png";
import IconeRejeitar from "assets/icones/operacoes/rejeitar.png";
import {
  Botao,
  Loader,
  SelectArredondado,
  SelectAutocomplete
} from "componentes";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import InputData from "componentes/inputTextoData";
import MaterialInputMascara from "componentes/inputTextoMascara";
import ModalOperacoes from "componentes/modalOperacoes";
import UploadCustomizado from "componentes/uploadCustomizado";
import { RotasDTO } from "global/rotas/rotasUrlDto";
import { usuarioPossuiFuncionalidade } from "servicos/funcionalidadesServico";
import { InterfaceDTO } from "global/dto/interfacesDto";
import ModalAcessos from "./Componentes/ModalAcessos";
import ModalDocumentos from "./Componentes/ModalDocumentos";
import Documento from "./Componentes/ModalExibirDocumento";
import ModalCancelamento from "./Componentes/ModalCancelamento";
import ModalHistoricoContato from "./Componentes/ModalHistoricoContato";
import ModalTrocaTitularidadeFatura from "./Componentes/ModalTrocaTitularidadeFatura";
import enumPassoOnboarding from "./enumPassoOnboarding";
import Notificacao from "./Componentes/Notificacao";
import VisualizarCancelamento from "./Componentes/VisualizarCancelamento";
import SuspenderCancelamento from "./Componentes/SuspenderCancelamento";
import Devolucao from "./Componentes/Devolucao";

// Styles
import {
  ButtonLinkLuz,
  Icone,
  Tabela,
  TabelaContent,
  TextoTroca,
  useStyles,
  IconeMenor
} from "./style";

// Helper
import operacaoControleHelper from "./helper";
import FormularioCancelamento from "./Componentes/FomularioCancelamento";

const AcompanhamentoCliente = () => {
  const { id, nome } = useParams();
  const classes = useStyles();

  // #region useState
  const [modalAcaoAcessarDocumentos, setModalAcaoAcessarDocumentos] = useState(
    false
  );
  const [
    modalAcaoAcessarHistoricoContato,
    setModalAcaoAcessarHistoricoContato
  ] = useState(false);
  const [
    modalAcaoTrocaTitularidadeFatura,
    setModalAcaoTrocaTitularidadeFatura
  ] = useState(false);
  const [listaTipoProblema, setListaTipoProblema] = useState();
  const [listaTipoDocumento, setListaTipoDocumento] = useState();
  const [listaUnidadeConsumidora, setListaUnidadeConsumidora] = useState();
  const [listaClienteBusca, setListaClienteBusca] = useState([]);
  const [clienteId, setClienteId] = useState();
  const [campoPesquisa, setCampoPesquisa] = useState("CPF");
  const [mascaraCpfCnpj, setMascaraCpfCnpj] = useState("###.###.###-##");
  const [labelCpfCnpj, setLabelCpfCnpj] = useState("Pesquisar por CPF");
  const [textoBusca, setTextoBusca] = useState("Pesquisar por CPF");
  const [passoOnboarding, setPassoOnboarding] = useState(" ");
  const [clienteNome, setClienteNome] = useState();
  const [numeroInstalacao, setNumeroInstalacao] = useState();
  const [unidadeId, setUnidadeId] = useState(0);
  const [cnpjCpf, setCnpjCpf] = useState(false);
  const [labelCnpjCpf, setLabelCnpjCpf] = useState();
  const [operacaoControleId, setOperacaoControleId] = useState(0);
  const [criouSenha, setCriouSenha] = useState();
  const [dataAcesso, setDataAcesso] = useState(" ");
  const [uploadDocumentos, setUploadDocumentos] = useState();
  const [dataEnvioEmailCobranca, setDataEnvioEmailCobranca] = useState();
  const [dataAssinatura, setDataAssinatura] = useState();
  const [
    dataSolicitacaoContatoDocIncorreto,
    setDataSolicitacaoContatoDocIncorreto
  ] = useState();
  const [
    dataPrimeiroContatoCliente,
    setDataPrimeiroContatoCliente
  ] = useState();
  const [documentoComProblema, setDocumentoComProblema] = useState();
  const [dataRecebimentoDocumento, setDataRecebimentoDocumento] = useState();
  const [
    dataEnvioEmailDocumentoCorreto,
    setDataEnvioEmailDocumentoCorreto
  ] = useState();
  const [listaControleDocumento, setListaControleDocumento] = useState();
  const [listaClienteDocumento, setListaClienteDocumento] = useState();
  const [listaControleComunicacao, setListaControleComunicacao] = useState([]);
  const [
    dataSolicitacaoContatoDocPendente,
    setDataSolicitacaoContatoDocPendente
  ] = useState();
  const [dataEnvioJuridico, setDataEnvioJuridico] = useState();
  const [aprovadoPeloJuridico, setAprovadoPeloJuridico] = useState();
  const [justificativaJuridico, setJustificativaJuridico] = useState();
  const [dataRetornoJuridico, setDataRetornoJuridico] = useState();
  const [pessoaFisicaJuridica, setPessoaFisicaJuridica] = useState();
  const [motivoCancelamento, setMotivoCancelamento] = useState();
  const [observacao, setObservacao] = useState();
  const [modalDocumento, setModalDocumento] = useState(false);
  const [carregandoOperacao, setCarregandoOperacao] = useState();
  const [dadosUrls, setDadosUrls] = useState();
  const [listaAcessos, setListaAcessos] = useState([]);
  const [modalAcessos, setModalAcessos] = useState(false);
  const [
    carregandoEnviarEmailSolicitacaoDocumento,
    setCarregandoEnviarEmailSolicitacaoDocumento
  ] = useState();
  const [
    carregandoContatarClienteDocumentoPendente,
    setCarregandoContatarClienteDocumentoPendente
  ] = useState();
  const [
    carregandoContatarClienteDocumentoIncorretos,
    setCarregandoContatarClienteDocumentoIncorretos
  ] = useState();
  const [
    carregandoEnviarEmailSolicitacaoDocumentosCorretos,
    setCarregandoEnviarEmailSolicitacaoDocumentosCorretos
  ] = useState();
  const [
    btnEnviarEmailSolicitacaoDocumentoDisabled,
    setBtnEnviarEmailSolicitacaoDocumentoDisabled
  ] = useState(false);
  const [
    btnEnviarEmailDocumentosPendentesDisabled,
    setBtnEnviarEmailDocumentosPendentesDisabled
  ] = useState(false);
  const [
    btnEnviarEmailDocumentoIncorretoDisabled,
    setBtnEnviarEmailDocumentoIncorretoDisabled
  ] = useState(false);
  const [
    btnEnviarEmailSolicitacaoDocumentoCorretoDisabled,
    setBtnEnviarEmailSolicitacaoDocumentoCorretoDisabled
  ] = useState(false);
  const [
    btnSalvarValidacaoDocumentoDisabled,
    setBtnSalvarValidacaoDocumentoDisabled
  ] = useState(false);

  // useState - TROCA TRITULARIDADE
  const [btnNovaTrocaDisabled, setBtnNovaTrocaDisabledDisabled] = useState(
    false
  );
  const [
    btnEnviarEmailTrocaTitularidadeDisabled,
    setBtnEnviarEmailTrocaTitularidadeDisabled
  ] = useState(false);
  const [
    carregandoEnviarEmailTrocaTitularidade,
    setCarregandoEnviarEmailTrocaTitularidade
  ] = useState();
  const [listaOperacaoStatusTroca, setListaOperacaoStatusTroca] = useState();
  const [
    ttOperacaoTrocaTitularidadeId,
    setTtOperacaoTrocaTitularidadeId
  ] = useState();
  const [
    ttListaHitoricoTitularidade,
    setTtListaHitoricoTitularidade
  ] = useState();
  const [
    ttListaTrocaTitularidadeFaturaVigente,
    setTtListaTrocaTitularidadeFaturaVigente
  ] = useState();
  const [
    ttOperacaoTrocaTitularidadeVigente,
    setTtOperacaoTrocaTitularidadeVigente
  ] = useState(null);
  const [ttDataInicio, setTtDataInicio] = useState("");
  const [ttNumeroProtocolo, setTtNumeroProtocolo] = useState();
  const [ttDataPrevisaoConclusao, setTtDataPrevisaoConclusao] = useState();
  const [
    ttEmailProcessoTrocaEnviado,
    setTtEmailProcessoTrocaEnviado
  ] = useState();
  const [ttTrocaFinalizada, setTtTrocaFinalizada] = useState();
  const [ttOperacaoStatusTrocaId, setTtOperacaoStatusTrocaId] = useState();
  const [
    ttNecessidadeVisitaPresencial,
    setTtNecessidadeVisitaPresencial
  ] = useState();
  const [ttDataFinalizacaoTroca, setTtDataFinalizacaoTroca] = useState("");
  const [
    ttDiaVencimentoDistribuidora,
    setTtDiaVencimentoDistribuidora
  ] = useState();
  const [ttDiaVencimentoLuz, setTtDiaVencimentoLuz] = useState();
  const [ttFaturaAntigaEmitida, setTtFaturaAntigaEmitida] = useState();
  const [ttClienteAssociadoNaUC, setTtClienteAssociadoNaUC] = useState();
  const [ttClienteIncluidoUsina, setTtClienteIncluidoUsina] = useState();
  const [
    ttPorcentagemEntradaDoCliente,
    setTtPorcentagemEntradaDoCliente
  ] = useState();
  const [ttRateioAtualizado, setTtRateioAtualizado] = useState();
  const [ttObservacao, setTtObservacao] = useState();
  const [arquivos, setArquivos] = useState([]);
  const [documentosSelecionados, setDocumentosSelecionados] = useState([]);
  const [
    btnMedidorInstaladoDisabled,
    setBtnMedidorInstaladoDisabled
  ] = useState(false);
  const [operacaoPassoOnboardingId, setOperacaoPassoOnboardingId] = useState();
  const { setError, errors, clearErrors } = useForm({
    reValidateMode: "onChange"
  });
  const [modalCancelamemto, setModalCancelamento] = useState(0);
  const [dadosSimulacao, setDadosSimulacao] = useState();
  const [notificacao, setNotificacao] = useState();
  const [dadosCancelamento, setDadosCancelamento] = useState();
  const [officer, setOfficer] = useState();
  const [devolucao, setDevolucao] = useState();
  const [
    exibirSolicitacaoCancelamento,
    setExibirSolicitacaoCancelamento
  ] = useState(true);
  const [statusCancelamentoHtml, setStatusCancelamentoHtml] = useState();
  const [
    exibirStatusEtapaCancelamento,
    setExibirStatusEtapaCancelamento
  ] = useState();
  const [nomeUnidade, setNomeUnidade] = useState();
  const [validarDocumentacaoUnidade, setValidarDocumentacaoUnidade] = useState(
    false
  );

  const usuario = useSelector((state) => state.usuario);
  const { menu } = usuario;
  const rotas = useSelector((state) => state.rotas);
  const suspensaoCancelamentoOperacoes = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.AcompanhamentoCliente,
    InterfaceDTO.SuspensaoCancelamentoOperacoes
  );

  // #endregion useState

  // #region metodos
  function zerarCampoTrocaTitularidade() {
    setBtnEnviarEmailTrocaTitularidadeDisabled(false);
    setTtListaHitoricoTitularidade();
    setTtOperacaoTrocaTitularidadeVigente(null);
    setTtListaTrocaTitularidadeFaturaVigente();
    setTtOperacaoTrocaTitularidadeId();
    setTtDataInicio();
    setTtNumeroProtocolo("");
    setTtDataPrevisaoConclusao();
    setTtEmailProcessoTrocaEnviado(" ");
    setTtTrocaFinalizada();
    setTtOperacaoStatusTrocaId();
    setTtNecessidadeVisitaPresencial();
    setTtDataFinalizacaoTroca("");
    setTtDiaVencimentoDistribuidora();
    setTtDiaVencimentoLuz();
    setTtFaturaAntigaEmitida();
    setTtClienteAssociadoNaUC(" ");
    setTtClienteIncluidoUsina();
    setTtPorcentagemEntradaDoCliente(" ");
    setTtObservacao(" ");
    setTtRateioAtualizado(" ");
    setBtnNovaTrocaDisabledDisabled(false);
  }

  function zerarCampos() {
    setPassoOnboarding(" ");
    setUploadDocumentos();
    setListaClienteDocumento();
    setDataEnvioEmailCobranca();
    setDataSolicitacaoContatoDocIncorreto();
    setDataPrimeiroContatoCliente();
    setListaControleComunicacao([]);
    setDocumentoComProblema();
    setDataRecebimentoDocumento();
    setListaControleDocumento();
    setDocumentosSelecionados([]);
    setDataEnvioEmailDocumentoCorreto();
    setDataSolicitacaoContatoDocPendente();
    setMotivoCancelamento();
    setObservacao();
    setPessoaFisicaJuridica();
    setDataEnvioJuridico();
    setAprovadoPeloJuridico();
    setJustificativaJuridico();
    setDataRetornoJuridico();
    setTextoBusca();
    setClienteId();
    setUnidadeId();
    setListaUnidadeConsumidora();
    setOperacaoControleId(0);
    setDataAssinatura();
    setBtnEnviarEmailSolicitacaoDocumentoDisabled(false);
    setBtnEnviarEmailDocumentosPendentesDisabled(false);
    setBtnEnviarEmailDocumentoIncorretoDisabled(false);
    setBtnEnviarEmailSolicitacaoDocumentoCorretoDisabled(false);
    setBtnSalvarValidacaoDocumentoDisabled(false);
    setBtnMedidorInstaladoDisabled(false);
    setOperacaoPassoOnboardingId();
    setValidarDocumentacaoUnidade(false);
  }

  const obterListaComunicacao = async (cpfCnpj) => {
    const resultado = await operacaoControleHelper.getListaComunicacao(cpfCnpj);

    if (resultado?.data) {
      setListaControleComunicacao(resultado?.data?.casos || []);
      setDataPrimeiroContatoCliente(
        moment(resultado?.data?.dataMaisRecente).isValid()
          ? moment(resultado?.data?.dataMaisRecente).format("DD/MM/yyyy")
          : "-"
      );
    }
  };

  const obterClienteUnidade = async (queryString) => {
    setCarregandoOperacao(true);
    setListaUnidadeConsumidora();
    setPassoOnboarding(" ");
    setUnidadeId(0);
    setOperacaoControleId(0);
    zerarCampoTrocaTitularidade();

    const resultado = await operacaoControleHelper.obterClienteUnidade(
      queryString
    );

    if (resultado != null) {
      setClienteNome(resultado?.data.nomeFantasia);
      setClienteId(resultado?.data.id);
      setCnpjCpf(resultado?.data.cnpjCpfFormatado);
      setListaUnidadeConsumidora(resultado?.data.listaUnidadeConsumidora);

      if (resultado?.data?.listaUnidadeConsumidora.length === 1)
        setUnidadeId(resultado?.data.listaUnidadeConsumidora[0].id);
    }
    obterListaComunicacao(resultado?.data?.cnpjCpf);

    setCarregandoOperacao(false);
  };

  const oncClickPesquisarCliente = () => {
    let queryString = "";

    if (campoPesquisa === "CPF" && textoBusca?.length === 11)
      queryString = `CnpjCpf=${textoBusca}`;
    else if (campoPesquisa === "CNPJ" && textoBusca?.length === 14)
      queryString = `CnpjCpf=${textoBusca}`;
    else if (campoPesquisa === "NOME" && clienteId !== null)
      queryString = `ClienteId=${clienteId}`;
    else if (campoPesquisa === "NUMERO_INSTALACAO" && textoBusca?.length > 3)
      queryString = `NumeroInstalacao=${textoBusca}`;

    if (queryString !== "") obterClienteUnidade(queryString);
  };

  const onInputChangeCliente = async (e, cliente) => {
    // eslint-disable-next-line no-underscore-dangle
    if (e?._reactName === "onChange" && cliente.length > 3) {
      const queryString = `nomeFantasia=${cliente}&QtdRegistro=10`;
      const resultado = await operacaoControleHelper.obterListaReduzida(
        queryString
      );

      if (resultado) setListaClienteBusca(resultado?.data?.clientes);
    }
  };

  const obterUsuarioOperacaoControlePorUnidadeId = async (unidade) => {
    setCarregandoOperacao(true);
    const resultado = await operacaoControleHelper.listarUsuarioOperacaoPorUnidadeId(
      unidade
    );

    if (resultado != null) {
      setListaAcessos(resultado?.data?.listaUsuario);
      setCriouSenha(resultado?.data?.listaUsuario[0]?.senhaCriada);
      setDataAcesso(
        resultado?.data?.listaUsuario[0]?.senhaCriada === "Sim"
          ? resultado?.data?.listaUsuario[0]?.dataUltimoLoginFormatada
          : resultado?.data?.listaUsuario[0]?.dataExpiracaoChaveAcessoFormatada
      );
    } else {
      setCriouSenha("Não");
      setDataAcesso(" ");
    }

    setCarregandoOperacao(false);
  };

  const onClickModalAcessarDocumentos = () =>
    setModalAcaoAcessarDocumentos(true);
  const onClickModalAcessarHistoricoContato = async () =>
    setModalAcaoAcessarHistoricoContato(true);
  const onClickModalTrocaTitularidadeFatura = () =>
    setModalAcaoTrocaTitularidadeFatura(true);

  function setBotoesValidacaoDocumento(dados) {
    setBtnEnviarEmailSolicitacaoDocumentoDisabled(
      dados?.btnEnvioEmailCobrancaDisabled
    );
    setBtnEnviarEmailDocumentosPendentesDisabled(
      dados?.btnSolicitacaoContatoDocPendenteDisabled
    );
    setBtnEnviarEmailSolicitacaoDocumentoCorretoDisabled(
      dados?.btnEnviarEmailSolicitacaoDocumentoCorretoDisabled
    );
    setBtnEnviarEmailDocumentoIncorretoDisabled(
      dados?.btnEnviarEmailDocumentoIncorretoDisabled
    );
    setBtnSalvarValidacaoDocumentoDisabled(
      dados?.btnSalvarValidacaoDocumentoDisabled
    );
  }

  const onCickSolicitarDocumento = async () => {
    if (!operacaoControleId) {
      operacaoControleHelper.exibirErro("Operação controle id não encontrado!");
      return;
    }
    setCarregandoOperacao(true);
    const resultado = await operacaoControleHelper.solicitaDocumento(
      operacaoControleId,
      !validarDocumentacaoUnidade
    );

    if (resultado != null && unidadeId > 0) {
      obterOperacaoControlePorUnidadeId(unidadeId);
    }

    setCarregandoOperacao(false);
  };

  const onChangeDiaVencimentoLuz = (dia) => {
    if (!dia) setTtDiaVencimentoLuz();
    else if (dia === 1) setTtDiaVencimentoLuz(30);
    else if (dia === 2) setTtDiaVencimentoLuz(31);
    else setTtDiaVencimentoLuz(dia - 2);
  };

  function setCamposOperacaoTrocaTitularidade(dados) {
    setBtnNovaTrocaDisabledDisabled(
      dados?.operacaoTrocaTitularidadeVigente?.btnNovaTrocaDisabled
    );
    setBtnEnviarEmailTrocaTitularidadeDisabled(
      dados?.btnEnviarEmailInformacaoTrocaDisabled
    );
    setTtListaHitoricoTitularidade(dados?.listaOperacaoTrocaTitularidade);
    setTtOperacaoTrocaTitularidadeVigente(
      dados?.operacaoTrocaTitularidadeVigente
    );
    setTtListaTrocaTitularidadeFaturaVigente(
      dados?.operacaoTrocaTitularidadeVigente
        ?.listaOperacaoTrocaTitularidadeFaturas
    );
    setTtOperacaoTrocaTitularidadeId(
      dados?.operacaoTrocaTitularidadeVigente?.id
    );
    setTtDataInicio(
      dados?.operacaoTrocaTitularidadeVigente?.dataInicioFormatada
    );
    setTtNumeroProtocolo(
      dados?.operacaoTrocaTitularidadeVigente?.numeroProtocolo ?? ""
    );
    setTtDataPrevisaoConclusao(
      dados?.operacaoTrocaTitularidadeVigente?.dataPrevisaoConclusao
    );
    setTtEmailProcessoTrocaEnviado(dados?.dataEnvioEmailCobrancaFormatada);
    setTtTrocaFinalizada(
      dados?.operacaoTrocaTitularidadeVigente?.trocaFinalizada
    );
    setTtOperacaoStatusTrocaId(
      dados?.operacaoTrocaTitularidadeVigente?.operacaoStatusTrocaId
    );
    setTtNecessidadeVisitaPresencial(
      dados?.operacaoTrocaTitularidadeVigente?.necessidadeVisitaEspecial
    );
    setTtDataFinalizacaoTroca(
      dados?.operacaoTrocaTitularidadeVigente?.dataFinalizacaoTroca
    );
    setTtFaturaAntigaEmitida(
      dados?.operacaoTrocaTitularidadeVigente?.faturaAntigaEmitida
    );
    setTtPorcentagemEntradaDoCliente(
      dados?.operacaoTrocaTitularidadeVigente?.porcentagemEntradaDoCliente ??
        "0"
    );
    setTtObservacao(dados?.operacaoTrocaTitularidadeVigente?.observacao ?? " ");
    setTtRateioAtualizado(
      dados?.operacaoTrocaTitularidadeVigente
        ?.operacaoTrocaTitularidadeRateioVigente?.rateioAtualizado ?? "0"
    );
  }

  const onClickSalvarFormularioOperacaoControle = async () => {
    setCarregandoOperacao(true);

    const listaControleDocumentoUpdate = [];

    if (listaControleDocumento.length) {
      const documentoSemMotivo = listaControleDocumento.find(
        (doc) => doc.operacaoTipoProblemaId === 0
      );

      if (documentoSemMotivo) {
        operacaoControleHelper.exibirErro(
          "Necessário escolher um motivo do documento incorreto!"
        );
        window.scrollTo(0, 0);
        setCarregandoOperacao(false);
        return;
      }
    }

    listaControleDocumento.forEach((item) => {
      const documentoId = item?.id < 0 ? 0 : item?.id;
      const novoItem = {
        id: documentoId,
        operacaoTipoProblemaId: item?.operacaoTipoProblemaId,
        tipoDocumentoId: item?.tipoDocumentoId
      };
      listaControleDocumentoUpdate.push(novoItem);
    });

    let docProblema = null;
    if (documentoComProblema === "NÃO") docProblema = false;
    else if (documentoComProblema === "SIM") docProblema = true;

    if (
      !docProblema &&
      operacaoPassoOnboardingId > 5 &&
      operacaoPassoOnboardingId !== 16
    ) {
      if (!dadosUrls?.unidade || !dadosUrls?.boleta) {
        operacaoControleHelper.exibirErro(
          "Necessário completar cadastro de unidade ou boleta!"
        );
        window.scrollTo(0, 0);
        setCarregandoOperacao(false);
        return;
      }
    }

    const resultado = await operacaoControleHelper.atualizarOperacaoControle(
      operacaoControleId,
      {
        motivoCancelamentoCliente: motivoCancelamento,
        observacao,
        documentoProblema: docProblema,
        listaControleDocumento: listaControleDocumentoUpdate
      }
    );

    if (resultado != null) {
      setPassoOnboarding(resultado?.data?.operacaoPassoOnboarding?.descricao);
      setBotoesValidacaoDocumento(resultado?.data);
      setCamposOperacaoTrocaTitularidade(resultado?.data);
    }

    setCarregandoOperacao(false);
  };

  const onClickSalvarFormularioTrocaTitularidade = async () => {
    let temErros = false;

    if (ttTrocaFinalizada === "S" && !ttDataFinalizacaoTroca) {
      setError("ttDataFinalizacaoTroca", {
        message: "Campo obrigatório!",
        type: "required"
      });
      temErros = true;
    }
    if (ttTrocaFinalizada !== "S" || ttDataFinalizacaoTroca)
      clearErrors(["ttDataFinalizacaoTroca"]);

    if (!ttDataPrevisaoConclusao) {
      setError("ttDataPrevisaoConclusao", {
        message: "Campo obrigatório!",
        type: "required"
      });
      temErros = true;
    }

    if (ttDataFinalizacaoTroca && ttTrocaFinalizada !== "S") {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto:
          "Data de finalização da troca deve ser preenchida e Troca Finalizada  = Sim."
      });
      temErros = true;
    }

    if (ttDataPrevisaoConclusao) clearErrors(["ttDataPrevisaoConclusao"]);

    if (!ttNumeroProtocolo) {
      setError("ttNumeroProtocolo", {
        message: "Campo obrigatório!",
        type: "required"
      });
      temErros = true;
    }
    if (ttNumeroProtocolo) clearErrors(["ttNumeroProtocolo"]);

    if (!ttOperacaoStatusTrocaId) {
      setError("ttOperacaoStatusTrocaId", {
        message: "Campo obrigatório!",
        type: "required"
      });
      temErros = true;
    }
    if (ttOperacaoStatusTrocaId) clearErrors(["ttOperacaoStatusTrocaId"]);

    if (!ttTrocaFinalizada) {
      setError("ttTrocaFinalizada", {
        message: "Campo obrigatório!",
        type: "required"
      });
      temErros = true;
    }
    if (ttTrocaFinalizada) clearErrors(["ttTrocaFinalizada"]);

    if (temErros) return;

    setCarregandoOperacao(true);

    const form = new FormData();

    form.append("operacaoControleId", operacaoControleId);
    if (ttDataInicio)
      form.append("dataInicio", moment(ttDataInicio).format("yyyy-MM-DD"));
    if (ttNumeroProtocolo) form.append("numeroProtocolo", ttNumeroProtocolo);
    if (ttDataPrevisaoConclusao)
      form.append(
        "dataPrevisaoConclusao",
        moment(ttDataPrevisaoConclusao).format("yyyy-MM-DD")
      );
    if (ttTrocaFinalizada) form.append("trocaFinalizada", ttTrocaFinalizada);
    if (ttOperacaoStatusTrocaId)
      form.append("operacaoStatusTrocaId", ttOperacaoStatusTrocaId);
    if (ttNecessidadeVisitaPresencial)
      form.append("necessidadeVisitaEspecial", ttNecessidadeVisitaPresencial);
    if (ttDiaVencimentoDistribuidora)
      form.append("diaVencimentoDistribuidora", ttDiaVencimentoDistribuidora);
    if (ttDiaVencimentoLuz) form.append("diaVencimentoLUZ", ttDiaVencimentoLuz);
    if (ttFaturaAntigaEmitida)
      form.append("faturaAntigaEmitida", ttFaturaAntigaEmitida);
    if (ttPorcentagemEntradaDoCliente)
      form.append(
        "porcentagemEntradaDoCliente",
        ttPorcentagemEntradaDoCliente.toString().replace(".", ",")
      );
    if (ttObservacao) form.append("observacao", ttObservacao);
    if (ttRateioAtualizado)
      form.append(
        "rateioAtualizado",
        ttRateioAtualizado.toString().replace(".", ",")
      );
    if (ttDataFinalizacaoTroca)
      form.append(
        "dataFinalizacaoTroca",
        moment(ttDataFinalizacaoTroca).format("yyyy-MM-DD")
      );

    form.append("clienteIncluidoNaUsina", ttClienteIncluidoUsina ? "S" : "N");

    arquivos.forEach((element) => {
      form.append("faturas", element);
    });

    const resultado = await operacaoControleHelper.atualizarOperacaoTrocaTitularidade(
      ttOperacaoTrocaTitularidadeId,
      form
    );

    setTtDataFinalizacaoTroca(resultado?.dataFinalizacaoTroca);
    setTtListaTrocaTitularidadeFaturaVigente(
      resultado?.listaOperacaoTrocaTitularidadeFaturas
    );
    setPassoOnboarding(resultado?.operacaoPassoOnboarding?.descricao);
    setBtnNovaTrocaDisabledDisabled(resultado?.btnNovaTrocaDisabled);
    setBtnMedidorInstaladoDisabled(resultado?.btnMedidorInstaladoDisabled);

    const indiceTitularidade = ttListaHitoricoTitularidade.findIndex(
      (objeto) => objeto.id === resultado?.id
    );
    if (indiceTitularidade !== -1)
      ttListaHitoricoTitularidade[indiceTitularidade] = resultado;

    setCarregandoOperacao(false);
  };

  const onClickSalvarNovaTroca = async () => {
    setCarregandoOperacao(true);
    const resultado = await operacaoControleHelper.salvarOperacaoTrocaTitularidade(
      {
        operacaoControleId
      }
    );

    if (resultado) {
      setPassoOnboarding(resultado?.data?.operacaoPassoOnboarding?.descricao);
      setCamposOperacaoTrocaTitularidade(resultado?.data);
    }

    setCarregandoOperacao(false);
  };

  function setCamposComunicacao(dados) {
    setDataEnvioEmailCobranca(dados?.dataEnvioEmailCobrancaFormatada);
    setDataSolicitacaoContatoDocPendente(
      dados?.dataSolicitacaoContatoDocPendenteFormatada
    );
    setDataEnvioEmailDocumentoCorreto(
      dados?.dataEnvioEmailDocumentoCorretoFormatada
    );
    setDataSolicitacaoContatoDocIncorreto(
      dados?.dataSolicitacaoContatoDocIncorretoFormatada
    );
    setTtEmailProcessoTrocaEnviado(dados?.emailTrocaProcessoEnviadoFormatado);
  }

  const enviarEmailSolicitacaoDocumento = async () => {
    setCarregandoEnviarEmailSolicitacaoDocumento(true);
    const resultado = await operacaoControleHelper.enviarEmailOperacaoControle({
      clienteId,
      operacaoControleId,
      TipoEmail: "OperacaoControleSolicitacaoDocumento"
    });

    setCamposComunicacao(resultado?.data);
    setCarregandoEnviarEmailSolicitacaoDocumento(false);
  };

  const contatarClienteDocumentoPendente = async () => {
    setCarregandoContatarClienteDocumentoPendente(true);
    const resultado = await operacaoControleHelper.enviarEmailOperacaoControle({
      clienteId,
      operacaoControleId,
      TipoEmail: "OperacaoControleDocumentoPendente"
    });

    setCamposComunicacao(resultado?.data);
    setCarregandoContatarClienteDocumentoPendente(false);
  };

  const onClickSimular = async () => {
    setCarregandoOperacao(true);
    const resultadoSalvar = await operacaoControleHelper.salvarCancelamentoSimulacao(
      { unidadeId }
    );

    if (resultadoSalvar?.data) {
      setDadosSimulacao(resultadoSalvar?.data);
      setModalCancelamento(1);
    }

    setCarregandoOperacao(false);
  };

  const obterDevolucao = async (id) => {
    setCarregandoOperacao(true);

    const resultadoDevolucao = await operacaoControleHelper.obterDevolucao(
      id,
      setNotificacao
    );

    if (resultadoDevolucao?.data?.items?.length) {
      setDevolucao(resultadoDevolucao?.data?.items[0]);
    }

    setCarregandoOperacao(false);
  };

  const retornaDataPrazo = (dataVigencia) => {
    if (!dataVigencia) return;

    if (moment(dataVigencia).isoWeekday() === 4)
      return moment(dataVigencia).add(5, "days");
    if (moment(dataVigencia).isoWeekday() === 5)
      return moment(dataVigencia).add(4, "days");
    if (moment(dataVigencia).isoWeekday() === 6)
      return moment(dataVigencia).add(3, "days");
    return moment(dataVigencia).add(2, "days");
  };

  const validarCancelamento = (dataVigencia, dataServidor) => {
    const data1 = retornaDataPrazo(dataVigencia);
    const data2 = moment(dataServidor);

    if (
      officer?.cargoId === 5 &&
      dadosCancelamento?.status === "AT" &&
      data1 > data2
    )
      return true;
    if (
      officer?.cargoId === 7 &&
      dadosCancelamento?.status === "AT" &&
      data1 > data2
    )
      return true;

    return false;
  };

  const obterStatus = async () => {
    setCarregandoOperacao(true);
    const resultadoStatus = await operacaoControleHelper.obterStatusCancelamento(
      unidadeId,
      setNotificacao
    );

    if (
      resultadoStatus?.data?.etapaCancelamentoId === 1 ||
      resultadoStatus?.status === 404
    ) {
      setStatusCancelamentoHtml(
        <div className={`${classes.botaoStatus} ${classes.ativo}`}>
          Cliente Ativo
        </div>
      );
      setExibirSolicitacaoCancelamento(true);
      setExibirStatusEtapaCancelamento(
        resultadoStatus?.data?.etapaCancelamentoId
      );
    }
    if (resultadoStatus?.data?.etapaCancelamentoId === 2) {
      setStatusCancelamentoHtml(
        <div className={`${classes.botaoStatus} ${classes.solicitacao}`}>
          Solicitação de cancelamento
        </div>
      );
      setExibirSolicitacaoCancelamento(true);
      setExibirStatusEtapaCancelamento(
        resultadoStatus?.data?.etapaCancelamentoId
      );
    }
    if (resultadoStatus?.data?.etapaCancelamentoId === 3) {
      setStatusCancelamentoHtml(
        <div className={`${classes.botaoStatus} ${classes.devolucaoAndamento}`}>
          Devolução em andamento
        </div>
      );
      setExibirSolicitacaoCancelamento(false);
      setExibirStatusEtapaCancelamento(
        resultadoStatus?.data?.etapaCancelamentoId
      );
    }

    if (resultadoStatus?.data?.etapaCancelamentoId === 4) {
      setStatusCancelamentoHtml(
        <div className={`${classes.botaoStatus} ${classes.devolucaoAtrasada}`}>
          Atrasado no processo de devolução
        </div>
      );
      setExibirSolicitacaoCancelamento(false);
      setExibirStatusEtapaCancelamento(
        resultadoStatus?.data?.etapaCancelamentoId
      );
    }

    if (resultadoStatus?.data?.etapaCancelamentoId === 5) {
      setStatusCancelamentoHtml(
        <div className={`${classes.botaoStatus} ${classes.devolucaoConcluida}`}>
          Devolução concluída
        </div>
      );
      setExibirSolicitacaoCancelamento(false);
      setExibirStatusEtapaCancelamento(
        resultadoStatus?.data?.etapaCancelamentoId
      );
    }

    if (resultadoStatus?.data?.etapaCancelamentoId === 6) {
      setStatusCancelamentoHtml(
        <div className={`${classes.botaoStatus} ${classes.cancelado}`}>
          Cliente cancelado
        </div>
      );
      setExibirSolicitacaoCancelamento(false);
      setExibirStatusEtapaCancelamento(
        resultadoStatus?.data?.etapaCancelamentoId
      );
    }

    if (resultadoStatus?.data?.etapaCancelamentoId === 7) {
      setStatusCancelamentoHtml(
        <div
          className={`${classes.botaoStatus} ${classes.aguardandoAtendimento}`}
        >
          Aguardando atendimento
        </div>
      );
      setExibirSolicitacaoCancelamento(false);
      setExibirStatusEtapaCancelamento(
        resultadoStatus?.data?.etapaCancelamentoId
      );
    }

    if (resultadoStatus?.data?.etapaCancelamentoId === 8) {
      setStatusCancelamentoHtml(
        <div className={`${classes.botaoStatus} ${classes.ativo}`}>
          Cliente Ativo
        </div>
      );
      setExibirSolicitacaoCancelamento(false);
      setExibirStatusEtapaCancelamento(
        resultadoStatus?.data?.etapaCancelamentoId
      );
    }
  };

  const obterCancelamento = async () => {
    setCarregandoOperacao(true);
    const resultado = await operacaoControleHelper.obterCancelamento(
      unidadeId,
      setNotificacao
    );

    await obterStatus();

    if (resultado?.data?.items[0]?.status === "IN") {
      setDadosCancelamento(null);
      setOfficer(null);
      setDevolucao(null);
      setCarregandoOperacao(false);
      return;
    }

    if (resultado?.data?.items?.length) {
      setDadosCancelamento(resultado?.data?.items[0]);

      const resultadoOfficer = await operacaoControleHelper.obterCancelamentoCargoOfficer(
        unidadeId,
        setNotificacao
      );
      if (resultadoOfficer?.data) setOfficer(resultadoOfficer?.data);
    }

    if (resultado?.data?.items[0]?.id)
      obterDevolucao(resultado?.data?.items[0]?.id);

    setCarregandoOperacao(false);
  };

  const salvarCancelamento = async (dados, setBotao) => {
    setCarregandoOperacao(true);
    setBotao(true);
    const resultadoSalvar = await operacaoControleHelper.salvarCancelamento(
      {
        unidadeId: Number(unidadeId),
        ...dados
      },
      setNotificacao
    );

    setCarregandoOperacao(false);
    setBotao(false);

    if (resultadoSalvar?.data) {
      obterCancelamento();

      setDadosCancelamento(resultadoSalvar.data);
      setModalCancelamento(0);
      setDadosSimulacao();

      setNotificacao({
        aberto: true,
        tipo: "success",
        texto: "Solicitação de cancelamento criado com sucesso"
      });
    }
  };

  const enviarEmailSolicitacaoDocumentoCorreto = async () => {
    setCarregandoEnviarEmailSolicitacaoDocumentosCorretos(true);

    await onClickSalvarFormularioOperacaoControle();

    const resultado = await operacaoControleHelper.enviarEmailOperacaoControle({
      clienteId,
      operacaoControleId,
      TipoEmail: "OperacaoControleSolicitacaoDocumentoCorreto"
    });

    setCamposComunicacao(resultado?.data);
    setCarregandoEnviarEmailSolicitacaoDocumentosCorretos(false);
  };

  const contatarSobreDocumentoIncorreto = async () => {
    setCarregandoContatarClienteDocumentoIncorretos(true);
    const resultado = await operacaoControleHelper.enviarEmailOperacaoControle({
      clienteId,
      operacaoControleId,
      TipoEmail: "OperacaoControleDocumentoNaoAprovado"
    });

    setCamposComunicacao(resultado?.data);
    setCarregandoContatarClienteDocumentoIncorretos(false);
  };

  const enviarEmailInformacaoTroca = async () => {
    setCarregandoEnviarEmailTrocaTitularidade(true);
    const resultado = await operacaoControleHelper.enviarEmailOperacaoControle({
      clienteId,
      operacaoControleId,
      TipoEmail: "OperacaoControleTrocaTitularidade"
    });

    setCamposComunicacao(resultado?.data);
    setCarregandoEnviarEmailTrocaTitularidade(false);
  };

  function setCamposOperacaoControle(dados) {
    setListaTipoDocumento(dados?.listaTipoDocumento);
    setPassoOnboarding(dados?.operacaoPassoOnboarding?.descricao);
    setUploadDocumentos(dados?.uploadDocumento);
    setListaClienteDocumento(dados?.listaClienteDocumento);
    setDataEnvioEmailCobranca(dados?.dataEnvioEmailCobrancaFormatada);
    setDataSolicitacaoContatoDocIncorreto(
      dados?.dataSolicitacaoContatoDocIncorretoFormatada
    );
    setDocumentoComProblema(dados?.documentoProblema);
    setDataRecebimentoDocumento(dados?.dataRececimentoDocumentoFormatada);
    setListaControleDocumento(dados?.listaControleDocumento);
    const documentosClienteComProblema = dados?.listaClienteDocumento.filter(
      (documento) =>
        dados?.listaControleDocumento.find(
          (item) => documento?.tipoDocumentoId === item?.tipoDocumentoId
        )
    );
    setDocumentosSelecionados(documentosClienteComProblema);
    setDataEnvioEmailDocumentoCorreto(
      dados?.dataEnvioEmailDocumentoCorretoFormatada
    );
    setDataSolicitacaoContatoDocPendente(
      dados?.dataSolicitacaoContatoDocPendenteFormatada
    );
    setMotivoCancelamento(dados?.motivoCancelamentoCliente);
    setObservacao(dados?.observacao);
    setPessoaFisicaJuridica(dados?.pessoaFisicaJuridica);
    setOperacaoPassoOnboardingId(dados?.operacaoPassoOnboardingId);
    setValidarDocumentacaoUnidade(dados?.validarDocumentacaoUnidade);

    if (dados?.pessoaFisicaJuridica === "PJ") {
      setLabelCnpjCpf("CNPJ");
      setDataEnvioJuridico(
        dados?.operacaoControlePJVigente?.dataEnvioJuridicoFormatada
      );
      setAprovadoPeloJuridico(
        dados?.operacaoControlePJVigente?.aprovadoFormatado
      );
      setJustificativaJuridico(
        dados?.operacaoControlePJVigente?.justificativaFormatado
      );
      setDataRetornoJuridico(
        dados?.operacaoControlePJVigente?.dataRetornoJuridicoFormatada
      );
    } else setLabelCnpjCpf("CPF");

    setBotoesValidacaoDocumento(dados);
    setBtnMedidorInstaladoDisabled(dados?.btnMedidorInstaladoDisabled);

    if (dados.operacaoTrocaTitularidadeVigente !== null)
      setCamposOperacaoTrocaTitularidade(dados);
    else zerarCampoTrocaTitularidade();
  }

  const verificarBoleta = async (operacaoId) => {
    const resultadoBoleta = await operacaoControleHelper.verificarCadastroBoleta(
      operacaoId
    );

    if (resultadoBoleta?.data) {
      setDadosUrls(resultadoBoleta?.data);
    }
  };

  const obterUnidadeUsinaPorUnidadeId = async (operacaoId) => {
    const resultadoUnidadeUsina = await operacaoControleHelper.obterUnidadeUsinaPorUnidadeId(
      operacaoId
    );

    if (resultadoUnidadeUsina?.data) {
      setTtClienteAssociadoNaUC(
        resultadoUnidadeUsina?.data?.associacaoNomeFantasia ?? ""
      );
      setTtClienteIncluidoUsina(
        resultadoUnidadeUsina?.data?.usinaLuzNomeFantasia ?? ""
      );
    }
  };

  const obterOperacaoControlePorUnidadeId = async (controleUnidadeId) => {
    setCarregandoOperacao(true);
    const resultado = await operacaoControleHelper.obterOperacaoControlePorUnidadeId(
      controleUnidadeId
    );

    if (resultado != null) {
      setCamposOperacaoControle(resultado?.data);
      setDataAssinatura(resultado?.data?.dataCadastroFormatada);
      setOperacaoControleId(resultado?.data.id);
      verificarBoleta(resultado?.data.id);
    } else setOperacaoControleId(0);

    setCarregandoOperacao(false);
  };

  const listarOperacaoTipoProblema = async () => {
    const resultado = await operacaoControleHelper.listarOperacaoTipoProblema();
    setListaTipoProblema(resultado?.data.listaOperacaoTipoProblema);
  };

  const listarOperacaoStatusTroca = async () => {
    const resultado = await operacaoControleHelper.listarOperacaoStatusTroca();
    setListaOperacaoStatusTroca(resultado?.data);
  };

  const onAddArquivoUpload = (lista) => {
    setArquivos(lista);
  };

  const onDeleteArquivoUpload = (lista) => {
    setArquivos((oldState) =>
      oldState?.filter((item) => item?.name !== lista.name)
    );
  };

  // #endregion metodos

  const carregarDadosIniciais = async () => {
    setCarregandoOperacao(true);
    setPassoOnboarding(" ");
    setCampoPesquisa("NOME");

    const resultado = await operacaoControleHelper.obterListaReduzida(
      `nomeFantasia=${nome}&QtdRegistro=10`
    );

    setListaClienteBusca(resultado?.data?.clientes);

    const resultadoCliente = await operacaoControleHelper.obterClienteUnidade(
      `ClienteId=${resultado?.data?.clientes[0].id}`
    );

    setClienteNome(resultadoCliente?.data.nomeFantasia);
    setClienteId(resultadoCliente?.data.id);
    setCnpjCpf(resultadoCliente?.data?.cnpjCpfFormatado);
    setListaUnidadeConsumidora(resultadoCliente?.data.listaUnidadeConsumidora);

    const unidades = resultadoCliente?.data?.listaUnidadeConsumidora?.filter(
      (und) => und.id === parseInt(id, 10)
    );

    if (unidades?.length) {
      setNomeUnidade(unidades[0]?.nomeUnidade);
      setNumeroInstalacao(unidades[0]?.numeroInstalacao);
      setTtDiaVencimentoDistribuidora(
        unidades[0]?.diaFaturamentoDistribuidora || ""
      );
      onChangeDiaVencimentoLuz(unidades[0]?.diaFaturamentoDistribuidora);
    }
    setUnidadeId(id);
    obterListaComunicacao(resultadoCliente?.data?.cnpjCpf);

    setCarregandoOperacao(false);
  };

  const onClickOnboardingFinalizado = async () => {
    setCarregandoOperacao(true);
    const resultado = await operacaoControleHelper.atualizarPassoOnboarding(
      operacaoControleId,
      enumPassoOnboarding.OnboardFinalizado
    );

    if (resultado != null) {
      setPassoOnboarding(resultado?.data?.operacaoPassoOnboarding?.descricao);
      setCamposOperacaoControle(resultado?.data);
      setOperacaoControleId(resultado?.data.id);
    }
    setCarregandoOperacao(false);
  };

  // #region useEffect

  useEffect(() => {
    setCarregandoOperacao(true);
    listarOperacaoTipoProblema();
    listarOperacaoStatusTroca();
    setCarregandoOperacao(false);
  }, []);

  useEffect(() => {
    if (unidadeId > 0) {
      setPassoOnboarding(" ");
      const unidades = listaUnidadeConsumidora?.filter(
        (und) => und.id === parseInt(unidadeId, 10)
      );
      setNumeroInstalacao(unidades[0]?.numeroInstalacao);
      obterUsuarioOperacaoControlePorUnidadeId(unidadeId);
      obterOperacaoControlePorUnidadeId(unidadeId);
      obterUnidadeUsinaPorUnidadeId({ unidadeId });
      obterCancelamento();
    }
  }, [unidadeId]);

  useEffect(() => {
    if (id > 0 && nome) {
      carregarDadosIniciais();
    }
  }, [id, nome]);

  useEffect(() => {
    if (campoPesquisa !== "UC") zerarCampos();

    if (campoPesquisa === "CPF") {
      setLabelCpfCnpj("Pesquisar por CPF");
      setMascaraCpfCnpj("###.###.###-##");
    } else if (campoPesquisa === "CNPJ") {
      setLabelCpfCnpj("Pesquisar por CNPJ");
      setMascaraCpfCnpj("##.###.###/####-##");
    } else if (campoPesquisa === "NUMERO_INSTALACAO") {
      setLabelCpfCnpj("Pesquisar por Número de Instalação");
      setMascaraCpfCnpj();
    }
  }, [campoPesquisa]);

  // #endregion useEffect

  return (
    <Loader loading={carregandoOperacao} className="w-auto">
      <Notificacao fechar={() => setNotificacao({})} mensagem={notificacao} />
      <ModalOperacoes
        titulo={`${clienteNome}, ${labelCnpjCpf} ${cnpjCpf}, UC ${numeroInstalacao}`}
        item={modalAcaoAcessarHistoricoContato}
        mensagem={
          <ModalHistoricoContato
            listaControleComunicacao={listaControleComunicacao}
          />
        }
        onCancelar={() => setModalAcaoAcessarHistoricoContato(false)}
      />
      <Documento
        item={modalDocumento}
        onCancelar={() => setModalDocumento(false)}
      />
      <ModalOperacoes
        titulo={`${clienteNome}, ${labelCnpjCpf} ${cnpjCpf}, UC ${numeroInstalacao}`}
        item={modalAcaoAcessarDocumentos}
        mensagem={
          <ModalDocumentos
            listaClienteDocumento={listaClienteDocumento}
            setModalDocumento={setModalDocumento}
            listaTipoProblema={listaTipoProblema}
            listaControleDocumento={listaControleDocumento}
            setListaControleDocumento={setListaControleDocumento}
            setDocumentoComProblema={setDocumentoComProblema}
            btnSalvarValidacaoDocumentoDisabled={
              btnSalvarValidacaoDocumentoDisabled
            }
            documentosSelecionados={documentosSelecionados}
            setDocumentosSelecionados={setDocumentosSelecionados}
          />
        }
        onCancelar={() => setModalAcaoAcessarDocumentos(false)}
      />
      <ModalOperacoes
        item={modalAcessos}
        mensagem={<ModalAcessos listaAcessos={listaAcessos} />}
        onCancelar={() => setModalAcessos(false)}
      />
      <ModalOperacoes
        titulo={`${clienteNome}, ${labelCnpjCpf} ${cnpjCpf}, UC ${numeroInstalacao}`}
        item={modalAcaoTrocaTitularidadeFatura}
        mensagem={
          <ModalTrocaTitularidadeFatura
            listaTrocaTitularidadeFaturaVigente={
              ttListaTrocaTitularidadeFaturaVigente
            }
          />
        }
        onCancelar={() => setModalAcaoTrocaTitularidadeFatura(false)}
      />
      <ModalCancelamento
        titulo="Simulação de cancelamento"
        item={modalCancelamemto === 1}
        conteudo={
          <VisualizarCancelamento
            dados={dadosSimulacao}
            onSolicitarCancelamento={() => setModalCancelamento(2)}
            exibirBotao
            onCancelar={() => setModalCancelamento(0)}
          />
        }
        onCancelar={() => setModalCancelamento(0)}
      />
      <ModalCancelamento
        titulo="Solicitação de cancelamento"
        item={modalCancelamemto === 2}
        conteudo={
          <FormularioCancelamento
            onSalvar={(dados, setBotao) => salvarCancelamento(dados, setBotao)}
            onCancelar={() => setModalCancelamento(0)}
            setNotificacao={setNotificacao}
            setCarregandoOperacao={setCarregandoOperacao}
          />
        }
        onCancelar={() => setModalCancelamento(0)}
      />
      <ModalCancelamento
        titulo="Visualização de cancelamento"
        item={modalCancelamemto === 3}
        conteudo={<VisualizarCancelamento dados={dadosCancelamento} />}
        onCancelar={() => setModalCancelamento(0)}
      />
      <ModalCancelamento
        titulo="Suspender cancelamento"
        item={modalCancelamemto === 4}
        conteudo={
          <SuspenderCancelamento
            dados={{ ...dadosCancelamento, ...officer }}
            onCancelar={() => setModalCancelamento(0)}
            setNotificacao={setNotificacao}
            setCarregandoOperacao={setCarregandoOperacao}
            obterCancelamento={obterCancelamento}
            nomeUnidade={nomeUnidade}
          />
        }
        onCancelar={() => setModalCancelamento(0)}
      />
      <ModalCancelamento
        titulo="Iniciar devolução"
        item={modalCancelamemto === 5}
        conteudo={
          <Devolucao
            onCancelar={() => setModalCancelamento(0)}
            setNotificacao={setNotificacao}
            setCarregandoOperacao={setCarregandoOperacao}
            obterCancelamento={obterCancelamento}
            id={dadosCancelamento?.id}
            nomeUnidade={nomeUnidade}
            dadosCancelamento={dadosCancelamento}
          />
        }
        onCancelar={() => setModalCancelamento(0)}
      />
      <ModalCancelamento
        titulo="Atualizar status"
        item={modalCancelamemto === 6}
        conteudo={
          <Devolucao
            dados={devolucao}
            onCancelar={() => setModalCancelamento(0)}
            setNotificacao={setNotificacao}
            setCarregandoOperacao={setCarregandoOperacao}
            obterCancelamento={obterCancelamento}
            id={dadosCancelamento?.id}
            nomeUnidade={nomeUnidade}
            dadosCancelamento={dadosCancelamento}
          />
        }
        onCancelar={() => setModalCancelamento(0)}
      />

      <Grid container py={2} spacing={2} className={classes.container}>
        <Grid item lg={3} md={4} sm={4} xs={12}>
          <SelectArredondado
            select
            id="CampoPesquisa"
            name="CampoPesquisa"
            label="Item de busca"
            placeholder="Item de busca"
            valueKey="valor"
            valueName="nome"
            dataSource={[
              { nome: "CPF", valor: "CPF" },
              { nome: "CNPJ", valor: "CNPJ" },
              { nome: "Nome", valor: "NOME" },
              { nome: "Número de Instalação", valor: "NUMERO_INSTALACAO" },
              { nome: "UC", valor: "UC" }
            ]}
            value={campoPesquisa}
            onChange={(e) => {
              setCampoPesquisa(e.target.value);
            }}
            permiteValorBranco
            styleForm={false}
          />
        </Grid>
        {campoPesquisa === "UC" ? (
          <Grid item lg={3} md={3} sm={5} xs={12} className="font-weight-bold">
            <SelectArredondado
              select
              id="unidadeConsumidora"
              name="unidadeConsumidora"
              label="Unidade Consumidora"
              placeholder="Unidade Consumidora"
              valueKey="id"
              valueName="nomeUnidade"
              dataSource={listaUnidadeConsumidora}
              value={unidadeId}
              onChange={(e) => {
                setUnidadeId(e.target.value);
              }}
              permiteValorBranco
              styleForm={false}
            />
          </Grid>
        ) : campoPesquisa === "NOME" ? (
          <Grid item lg={3} md={4} sm={5} xs={12}>
            <SelectAutocomplete
              disableClearable
              disableOpenOnFocus
              id="cliente"
              name="cliente"
              valueKey="id"
              marginBottom="0"
              label="Nome Cliente"
              placeholder="Nome Cliente"
              defaultValue={clienteId}
              value={clienteId}
              options={listaClienteBusca}
              getOptionLabel={(option) => option?.nomeFantasia ?? ""}
              onChange={(e, valor) => setClienteId(valor?.id)}
              onInputChange={(e, valor) => onInputChangeCliente(e, valor)}
              allowClear
              valueName="nomeFantasia"
            />
          </Grid>
        ) : (
          <Grid item lg={3} md={4} sm={4} xs={12} className="font-weight-bold">
            <MaterialInputMascara
              type="text"
              id="textoBusca"
              name="textoBusca"
              label={labelCpfCnpj}
              mask={mascaraCpfCnpj}
              value={textoBusca}
              renderIconShowHide={false}
              onInputChange={(valor) => setTextoBusca(valor)}
            />
          </Grid>
        )}
        <Grid item lg={2} md={4} sm={3} xs={12}>
          <Botao
            onClick={() => oncClickPesquisarCliente()}
            label="Pesquisar"
            className={classes.button}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <MaterialInputTexto
            type="text"
            id="passoOnboarding"
            name="passoOnboarding"
            label="Passo do Onboarding"
            disabled
            renderIconShowHide={false}
            defaultValue={passoOnboarding}
          />
        </Grid>
      </Grid>
      <Grid container py={2}>
        <Grid item xs={12} sm={8} md={9} lg={9} className={classes.title}>
          {operacaoControleId > 0
            ? `${clienteNome}, ${labelCnpjCpf} ${cnpjCpf}, UC ${numeroInstalacao}`
            : ""}
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          lg={3}
          display="flex"
          justifyContent="flex-end"
        >
          {operacaoPassoOnboardingId !== 14 ? (
            statusCancelamentoHtml && statusCancelamentoHtml
          ) : (
            <div className={`${classes.botaoStatus} ${classes.cancelado}`}>
              Cliente cancelado
            </div>
          )}
        </Grid>
        {dadosCancelamento &&
          exibirSolicitacaoCancelamento &&
          validarCancelamento(
            dadosCancelamento?.dataInicioVigencia,
            dadosCancelamento?.dataServidor
          ) && (
            <Grid item xs={12}>
              <div className={classes.suspensao}>
                <span>
                  Solicitação de cancelamento - Parceiro avisado - Prazo:{" "}
                  {retornaDataPrazo(
                    dadosCancelamento?.dataInicioVigencia
                  )?.format("DD [de] MMMM [de] YYYY")}
                </span>
                <button type="button" onClick={() => setModalCancelamento(4)}>
                  Suspender cancelamento
                </button>
              </div>
            </Grid>
          )}
      </Grid>
      <Card className={classes.card}>
        {operacaoControleId > 0 ? (
          <Grid container p={2} spacing={2} className={classes.container}>
            <Grid item xs={12} className={classes.title}>
              Validação de Documento
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center">
              <ButtonLinkLuz onClick={() => setModalAcessos(true)}>
                Todos os acessos
              </ButtonLinkLuz>
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="dataAssinatura"
                name="dataAssinatura"
                label="Data Assinatura"
                disabled
                renderIconShowHide={false}
                defaultValue={dataAssinatura}
              />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="criouSenha"
                name="criouSenha"
                label="Usuário criou senha?"
                disabled
                renderIconShowHide={false}
                defaultValue={criouSenha}
              />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="dataAcesso"
                name="dataAcesso"
                label={
                  criouSenha === "Sim"
                    ? "Data de Acesso"
                    : "Quando a chave expira?"
                }
                disabled
                renderIconShowHide={false}
                defaultValue={dataAcesso}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Loader
                loading={carregandoEnviarEmailSolicitacaoDocumento}
                className="w-auto"
              >
                <Botao
                  disabled={btnEnviarEmailSolicitacaoDocumentoDisabled}
                  onClick={() => enviarEmailSolicitacaoDocumento()}
                  label="Envio de e-mail para criar senha"
                  className={classes.button}
                />
              </Loader>
            </Grid>
            <Grid
              item
              lg={2}
              md={4}
              sm={6}
              xs={12}
              display="flex"
              alignItems="center"
            >
              <ButtonLinkLuz onClick={() => onClickModalAcessarDocumentos()}>
                Acessar Documentos
              </ButtonLinkLuz>
            </Grid>
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              display="flex"
              alignItems="center"
            >
              <ButtonLinkLuz onClick={() => onCickSolicitarDocumento()}>
                Solicitar Documento Unidade
                <IconeMenor
                  src={
                    validarDocumentacaoUnidade ? IconeAprovar : IconeRejeitar
                  }
                />
              </ButtonLinkLuz>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="uploadDocumento"
                name="uploadDocumento"
                label="Upload de Documento?"
                disabled
                renderIconShowHide={false}
                defaultValue={uploadDocumentos}
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="dataCobranca"
                name="dataCobranca"
                label="Data de envio de e-mail de cobrança"
                disabled
                renderIconShowHide={false}
                defaultValue={dataEnvioEmailCobranca}
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <Loader
                loading={carregandoEnviarEmailSolicitacaoDocumento}
                className="w-auto"
              >
                <Botao
                  disabled={btnEnviarEmailSolicitacaoDocumentoDisabled}
                  onClick={() => enviarEmailSolicitacaoDocumento()}
                  label="Enviar e-mail de solicitação de documentos"
                  className={classes.button}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="dataSolicitacaoContatoDocPendente"
                name="dataSolicitacaoContatoDocPendente"
                label="Data de solicitação de contato"
                disabled
                renderIconShowHide={false}
                defaultValue={dataSolicitacaoContatoDocPendente}
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <Loader
                loading={carregandoContatarClienteDocumentoPendente}
                className="w-auto"
              >
                <Botao
                  disabled={btnEnviarEmailDocumentosPendentesDisabled}
                  onClick={() => contatarClienteDocumentoPendente()}
                  label="Contatar Atendimento sobre documentos"
                  className={classes.button}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="dataPrimeiroContatoCliente"
                name="dataPrimeiroContatoCliente"
                label="Data de contato com cliente"
                disabled
                renderIconShowHide={false}
                defaultValue={dataPrimeiroContatoCliente}
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <ButtonLinkLuz
                onClick={() => onClickModalAcessarHistoricoContato()}
              >
                Acessar histórico de tentativas de contato com cliente
              </ButtonLinkLuz>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="documentoComProblema"
                name="documentoComProblema"
                label="Documento com problema?"
                disabled
                renderIconShowHide={false}
                defaultValue={documentoComProblema}
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="dataRecebimentoDoc"
                name="dataRecebimentoDoc"
                label="Data de recebimento do documento"
                disabled
                renderIconShowHide={false}
                defaultValue={dataRecebimentoDocumento}
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <Loader
                loading={carregandoContatarClienteDocumentoIncorretos}
                className="w-auto"
              >
                <Botao
                  disabled={btnEnviarEmailDocumentoIncorretoDisabled}
                  onClick={() => contatarSobreDocumentoIncorreto()}
                  label="Contatar Atendimento sobre documentos incorretos"
                  className={classes.button}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="dataSolicitacaoContatoDocIncorreto"
                name="dataSolicitacaoContatoDocIncorreto"
                label="Data solicitação contato"
                disabled
                renderIconShowHide={false}
                defaultValue={dataSolicitacaoContatoDocIncorreto}
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <Loader
                loading={carregandoEnviarEmailSolicitacaoDocumentosCorretos}
                className="w-auto"
              >
                <Botao
                  disabled={btnEnviarEmailSolicitacaoDocumentoCorretoDisabled}
                  onClick={() => enviarEmailSolicitacaoDocumentoCorreto()}
                  label="Enviar e-mail de solicitação de documentos corretos"
                  className={classes.button}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="dataSolicitacaoDocumentosCorretos"
                name="dataSolicitacaoDocumentosCorretos"
                label="Data de solicitação de documentos corretos"
                disabled
                renderIconShowHide={false}
                defaultValue={dataEnvioEmailDocumentoCorreto}
              />
            </Grid>
            <Grid item xs={12} my={2}>
              <TabelaContent>
                <Tabela>
                  <Tabela.Thead>
                    <Tabela.Tr>
                      <Tabela.ThLeft borderBottom>
                        Listagem de Documentos
                      </Tabela.ThLeft>
                      <Tabela.Th borderBottom>Descrição do Problema</Tabela.Th>
                    </Tabela.Tr>
                  </Tabela.Thead>
                  <Tabela.Tbody>
                    {listaControleDocumento?.map((item, index) => (
                      <Tabela.Tr key={index}>
                        <Tabela.TdLeft
                          borderBottom={
                            index === 0 ||
                            index !== listaControleDocumento?.length - 1
                          }
                        >
                          {
                            listaTipoDocumento.find(
                              (documento) =>
                                Number(documento.id) ===
                                Number(item?.tipoDocumentoId)
                            )?.documento
                          }
                        </Tabela.TdLeft>
                        <Tabela.Td
                          borderBottom={
                            index === 0 ||
                            index !== listaControleDocumento?.length - 1
                          }
                        >
                          {
                            listaTipoProblema.find(
                              (itemProblema) =>
                                Number(itemProblema.id) ===
                                Number(item?.operacaoTipoProblemaId)
                            )?.descricao
                          }
                        </Tabela.Td>
                      </Tabela.Tr>
                    ))}
                  </Tabela.Tbody>
                </Tabela>
              </TabelaContent>
            </Grid>
            <Grid item lg={3} md={4} xs={12}>
              <Botao
                onClick={() =>
                  dadosCancelamento ? setModalCancelamento(3) : onClickSimular()
                }
                label={
                  dadosCancelamento
                    ? "Visualizar cancelamento"
                    : "Simular cancelamento"
                }
                className={classes.button}
                disabled={
                  !dadosCancelamento &&
                  (operacaoPassoOnboardingId === 14 ||
                    devolucao?.devolucaoCancelamentoStatusResolucaoId === 1 ||
                    exibirStatusEtapaCancelamento === 5 ||
                    exibirStatusEtapaCancelamento === 6)
                }
              />
            </Grid>
            <Grid item lg={9} md={8} xs={12}>
              <MaterialInputTexto
                type="text"
                id="motivoCancelamento"
                name="motivoCancelamento"
                label="Motivo de Cancelamento do Cliente"
                renderIconShowHide={false}
                defaultValue={dadosCancelamento?.motivo || ""}
                permiteValorBranco
                disabled
              />
            </Grid>
            <Grid item lg={3} md={4} xs={12}>
              <Botao
                onClick={() =>
                  devolucao ? setModalCancelamento(6) : setModalCancelamento(5)
                }
                label={devolucao ? "Atualizar status" : "Iniciar devolução"}
                className={classes.button}
                disabled={
                  !dadosCancelamento ||
                  operacaoPassoOnboardingId === 14 ||
                  devolucao?.devolucaoCancelamentoStatusResolucaoId === 1 ||
                  exibirStatusEtapaCancelamento === 5 ||
                  exibirStatusEtapaCancelamento === 6
                }
              />
            </Grid>
            <Grid container item xs={12} md={8} lg={9} spacing={1}>
              <Grid item sm={6} xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="protocoloDistribuidora"
                  name="protocoloDistribuidora"
                  label="Protocolo da distribuidora"
                  renderIconShowHide={false}
                  defaultValue={devolucao?.protocoloDistribuidora || ""}
                  permiteValorBranco
                  disabled
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="dataPrevistaDevolucao"
                  name="dataPrevistaDevolucao"
                  label={
                    exibirStatusEtapaCancelamento === 5 ||
                    exibirStatusEtapaCancelamento === 6
                      ? "Data de conclusão"
                      : "Data prevista de devolução"
                  }
                  renderIconShowHide={false}
                  defaultValue={
                    devolucao?.dataPrevistaDevolucao
                      ? exibirStatusEtapaCancelamento === 5 ||
                        exibirStatusEtapaCancelamento === 6
                        ? moment(devolucao?.dataConclusao).format("DD/MM/YYYY")
                        : moment(devolucao?.dataPrevistaDevolucao).format(
                            "DD/MM/YYYY"
                          )
                      : ""
                  }
                  permiteValorBranco
                  disabled
                />
              </Grid>
            </Grid>
            {dadosCancelamento &&
            exibirSolicitacaoCancelamento &&
            suspensaoCancelamentoOperacoes &&
            operacaoPassoOnboardingId !== 14 ? (
              <>
                <Grid item lg={3} md={4} xs={12}>
                  <Botao
                    onClick={() => setModalCancelamento(4)}
                    label="Suspensão cancelamento"
                    className={classes.button}
                  />
                </Grid>
                <Grid item lg={9} md={8} xs={12}>
                  <MaterialInputTexto
                    type="text"
                    id="observacao"
                    name="observacao"
                    label="Observação (opcional)"
                    renderIconShowHide={false}
                    defaultValue={observacao}
                    onBlur={(valor) => setObservacao(valor.target.value)}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="observacao"
                  name="observacao"
                  label="Observação (opcional)"
                  renderIconShowHide={false}
                  defaultValue={observacao}
                  onBlur={(valor) => setObservacao(valor.target.value)}
                />
              </Grid>
            )}
            <Grid item lg={2} sx={{ display: { xs: "none", lg: "block" } }} />
            <Grid item xs={12} lg={8} my={2}>
              <TabelaContent>
                <Tabela>
                  <Tabela.Thead>
                    <Tabela.Tr>
                      <Tabela.ThLeft background="#F5F5F7">
                        Cliente
                      </Tabela.ThLeft>
                      <Tabela.ThLeft background="#F5F5F7">
                        Unidade
                      </Tabela.ThLeft>
                      <Tabela.Th background="#F5F5F7">Boleta</Tabela.Th>
                    </Tabela.Tr>
                  </Tabela.Thead>
                  <Tabela.Tbody>
                    <Tabela.Tr>
                      <Tabela.TdLeft>
                        {dadosUrls?.cliente ? (
                          <Icone src={IconeAprovar} />
                        ) : (
                          <Icone src={IconeRejeitar} />
                        )}
                      </Tabela.TdLeft>
                      <Tabela.TdLeft>
                        {dadosUrls?.unidade ? (
                          <Icone src={IconeAprovar} />
                        ) : (
                          <Icone src={IconeRejeitar} />
                        )}
                      </Tabela.TdLeft>
                      <Tabela.Td>
                        {dadosUrls?.boleta ? (
                          <Icone src={IconeAprovar} />
                        ) : (
                          <Icone src={IconeRejeitar} />
                        )}
                      </Tabela.Td>
                    </Tabela.Tr>
                    <Tabela.Tr>
                      <Tabela.TdLeft>
                        <a
                          href={dadosUrls?.cadastroClienteUrl}
                          className={classes.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Acesse aqui</span>
                          <LaunchIcon />
                        </a>
                      </Tabela.TdLeft>
                      <Tabela.TdLeft>
                        <a
                          href={dadosUrls?.cadastroUnidadeConsumidoraUrl}
                          className={classes.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Acesse aqui</span>
                          <LaunchIcon />
                        </a>
                      </Tabela.TdLeft>
                      <Tabela.Td>
                        <a
                          href={dadosUrls?.cadastroBoletaGDUrl}
                          className={classes.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Acesse aqui</span>
                          <LaunchIcon />
                        </a>
                      </Tabela.Td>
                    </Tabela.Tr>
                  </Tabela.Tbody>
                </Tabela>
              </TabelaContent>
            </Grid>
            {pessoaFisicaJuridica === "PJ" ? (
              <Grid container p={2} spacing={2} className={classes.container}>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <MaterialInputTexto
                    type="text"
                    id="dataEnvioJuridico"
                    name="dataEnvioJuridico"
                    label="Data de Envio para o Jurídico"
                    disabled
                    renderIconShowHide={false}
                    defaultValue={dataEnvioJuridico}
                  />
                </Grid>
                <Grid item lg={3} md={9} sm={6} xs={12}>
                  <MaterialInputTexto
                    type="text"
                    id="aprovadoPeloJuridico"
                    name="aprovadoPeloJuridico"
                    label="Aprovado pelo Jurídico?"
                    disabled
                    renderIconShowHide={false}
                    defaultValue={aprovadoPeloJuridico}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <MaterialInputTexto
                    type="text"
                    id="justificativaJuridico"
                    name="justificativaJuridico"
                    label="Justificativa do Jurídico"
                    disabled
                    renderIconShowHide={false}
                    defaultValue={justificativaJuridico}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <MaterialInputTexto
                    type="text"
                    id="dataRetornoJuridico"
                    name="dataRetornoJuridico"
                    label="Data de Retorno do Jurídico"
                    disabled
                    renderIconShowHide={false}
                    defaultValue={dataRetornoJuridico}
                  />
                </Grid>
              </Grid>
            ) : null}
            <Grid container p={2} spacing={2} className={classes.container}>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Botao
                  label="Salvar"
                  className={classes.button}
                  onClick={() => onClickSalvarFormularioOperacaoControle()}
                  disabled={btnSalvarValidacaoDocumentoDisabled}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Card>
      <Card className={classes.card}>
        {ttOperacaoTrocaTitularidadeVigente !== null ? (
          <Grid>
            <Grid container p={2} spacing={2} className={classes.container}>
              <Grid item xs={12} className="font-weight-bold">
                Troca de Titularidade
              </Grid>
              <Grid item xs={12} pb={1}>
                {ttListaHitoricoTitularidade?.map((item, index) => (
                  <TextoTroca key={index}>
                    {`Troca: ${item?.trocaNumero} - ${moment(
                      item?.dataInicioFormatada
                    ).format("DD/MM/YYYY")} -  protocolo: ${
                      item?.numeroProtocolo ?? ""
                    } - status: ${
                      item?.operacaoStatusTroca?.descricao ?? ""
                    } - data finalização: ${
                      item?.dataFinalizacaoTrocaFormatada
                    }`}
                  </TextoTroca>
                ))}
              </Grid>
              <Grid container spacing={2} item lg={7} sm={12} xs={12}>
                <Grid item lg={4} sm={6} xs={12}>
                  <InputData
                    type="text"
                    id="ttDataInicio"
                    name="ttDataInicio"
                    label="Data de Início"
                    customValue={ttDataInicio}
                    onChange={(data) => setTtDataInicio(data)}
                    renderIconShowHide={false}
                  />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <MaterialInputTexto
                    type="text"
                    id="ttClienteAssociadoUC"
                    name="ttClienteAssociadoUC"
                    label="Associação"
                    disabled
                    renderIconShowHide={false}
                    defaultValue={ttClienteAssociadoNaUC}
                  />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <MaterialInputTexto
                    type="text"
                    id="ttClienteIncluidoUsina"
                    name="ttClienteIncluidoUsina"
                    label="Usina"
                    disabled
                    renderIconShowHide={false}
                    defaultValue={ttClienteIncluidoUsina}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} item lg={5} sm={12} xs={12}>
                <Grid item sm={6} xs={12}>
                  <MaterialInputTexto
                    type="text"
                    id="ttNumeroProtocolo"
                    name="ttNumeroProtocolo"
                    label="Número do Protocolo"
                    renderIconShowHide={false}
                    defaultValue={ttNumeroProtocolo}
                    onBlur={(valor) => setTtNumeroProtocolo(valor.target.value)}
                    errors={errors}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputData
                    type="text"
                    id="ttDataPrevisaoConclusao"
                    name="ttDataPrevisaoConclusao"
                    label="Previsão de Conclusão"
                    // disabled={!habilitarCampos}
                    customValue={ttDataPrevisaoConclusao}
                    onChange={(data) => setTtDataPrevisaoConclusao(data)}
                    renderIconShowHide={false}
                    errors={errors}
                  />
                </Grid>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <SelectArredondado
                  select
                  id="ttTrocaFinalizada"
                  name="ttTrocaFinalizada"
                  label="Troca finalizada?"
                  placeholder="Troca finalizada?"
                  valueKey="valor"
                  valueName="nome"
                  dataSource={[
                    {
                      nome: "Selecione",
                      valor: ""
                    },
                    {
                      nome: "SIM",
                      valor: "S"
                    },
                    {
                      nome: "NÃO",
                      valor: "N"
                    }
                  ]}
                  value={ttTrocaFinalizada}
                  onChange={(e) => {
                    setTtTrocaFinalizada(e.target.value);
                  }}
                  permiteValorBranco
                  styleForm={false}
                  errors={errors}
                />
              </Grid>

              <Grid item lg={3} md={6} sm={6} xs={12}>
                <SelectArredondado
                  select
                  id="ttOperacaoStatusTrocaId"
                  name="ttOperacaoStatusTrocaId"
                  label="Status da Troca"
                  placeholder="Status da Troca"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={listaOperacaoStatusTroca}
                  value={ttOperacaoStatusTrocaId}
                  onChange={(e) => {
                    setTtOperacaoStatusTrocaId(e.target.value);
                  }}
                  permiteValorBranco
                  errors={errors}
                  styleForm={false}
                />
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <SelectArredondado
                  select
                  id="ttNecessidadeVisitaPresencial"
                  name="ttNecessidadeVisitaPresencial"
                  label="Necessidade de visita presencial?"
                  placeholder="Necessidade de visita presencial?"
                  valueKey="valor"
                  valueName="nome"
                  dataSource={[
                    {
                      nome: "Selecione",
                      valor: "--"
                    },
                    {
                      nome: "SIM",
                      valor: "S"
                    },
                    {
                      nome: "NÃO",
                      valor: "N"
                    }
                  ]}
                  value={ttNecessidadeVisitaPresencial}
                  onChange={(e) => {
                    setTtNecessidadeVisitaPresencial(e.target.value);
                  }}
                  permiteValorBranco
                  styleForm={false}
                  disabled={false}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <InputData
                  type="text"
                  id="ttDataFinalizacaoTroca"
                  name="ttDataFinalizacaoTroca"
                  label="Data de finalização da troca"
                  customValue={ttDataFinalizacaoTroca}
                  onChange={(data) => setTtDataFinalizacaoTroca(data)}
                  renderIconShowHide={false}
                  errors={errors}
                />
              </Grid>

              <Grid container spacing={2} item lg={7} sm={12} xs={12}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <SelectAutocomplete
                    disableClearable
                    disableOpenOnFocus
                    id="ttDiaVencimentoDistribuidora"
                    name="ttDiaVencimentoDistribuidora"
                    valueKey="id"
                    marginBottom="0"
                    label="Dia de vencimento da fatura distribuidora"
                    placeholder="Dia de vencimento da fatura distribuidora"
                    defaultValue={ttDiaVencimentoDistribuidora}
                    value={ttDiaVencimentoDistribuidora}
                    options={operacaoControleHelper.listaDias()}
                    getOptionLabel={(option) => option.valor}
                    onChange={(e, valor) => {
                      setTtDiaVencimentoDistribuidora(valor?.id || "");
                      onChangeDiaVencimentoLuz(valor?.id);
                    }}
                    allowClear
                    valueName="valor"
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <SelectAutocomplete
                    disableClearable
                    disableOpenOnFocus
                    id="ttDiaVencimentoLuz"
                    name="ttDiaVencimentoLuz"
                    valueKey="id"
                    marginBottom="0"
                    label="Dia de vencimento da fatura LUZ"
                    placeholder="Dia de vencimento da fatura LUZ"
                    defaultValue={ttDiaVencimentoLuz}
                    value={ttDiaVencimentoLuz}
                    options={operacaoControleHelper.listaDias()}
                    getOptionLabel={(option) => option.valor}
                    valueName="valor"
                    disabled
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <SelectArredondado
                    select
                    id="ttFaturaAntigaEmitida"
                    name="ttFaturaAntigaEmitida"
                    label="Faturas antigas emitidas?"
                    placeholder="Faturas antigas emitidas?"
                    valueKey="valor"
                    valueName="nome"
                    dataSource={[
                      {
                        nome: "Selecione",
                        valor: "--"
                      },
                      {
                        nome: "SIM",
                        valor: "S"
                      },
                      {
                        nome: "NÃO",
                        valor: "N"
                      }
                    ]}
                    value={ttFaturaAntigaEmitida}
                    onChange={(e) => {
                      setTtFaturaAntigaEmitida(e.target.value);
                    }}
                    permiteValorBranco
                    styleForm={false}
                    disabled={false}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} item lg={5} sm={12} xs={12}>
                <Grid item lg={6} sm={6} xs={12}>
                  <Loader
                    loading={carregandoEnviarEmailTrocaTitularidade}
                    className="w-auto"
                  >
                    <Botao
                      label="Enviar e-mail de informação de troca"
                      className={classes.button}
                      onClick={() => enviarEmailInformacaoTroca()}
                      disabled={btnEnviarEmailTrocaTitularidadeDisabled}
                    />
                  </Loader>
                </Grid>
                <Grid item lg={6} sm={6} xs={12}>
                  <MaterialInputTexto
                    type="text"
                    id="ttEmailProcessoTrocaEnviado"
                    name="ttEmailProcessoTrocaEnviado"
                    label="Data de envio do e-mail"
                    disabled
                    renderIconShowHide={false}
                    defaultValue={ttEmailProcessoTrocaEnviado}
                  />
                </Grid>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box
                  bgcolor="rgba(250, 62, 177, 0.08)"
                  border="2px dashed #FA3EB1"
                  borderRadius="8px"
                >
                  <UploadCustomizado
                    imageOrPdfValidate
                    onAdd={onAddArquivoUpload}
                    onDropRejected={onDeleteArquivoUpload}
                    progressValue={0}
                    acceptedFiles={["application/pdf"]}
                    dropAreaText="Carregar Faturas."
                    limit={1}
                    icon={Upload}
                    showLoader={false}
                  />
                </Box>
              </Grid>
              <Grid
                item
                lg={3}
                md={3}
                sm={6}
                xs={12}
                display="flex"
                alignItems="center"
              >
                <ButtonLinkLuz
                  onClick={() => onClickModalTrocaTitularidadeFatura()}
                >
                  Acessar Faturas
                </ButtonLinkLuz>
              </Grid>
              {arquivos &&
                arquivos.map((item, idx) => (
                  <Grid item xs={12} key={idx}>
                    <Chip
                      classes={{ root: classes.previewChip }}
                      label={item?.file?.name}
                      onDelete={() => setArquivos([])}
                      variant="outlined"
                    />
                  </Grid>
                ))}
              <Grid item mt={2} lg={12} md={12} sm={12} xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="ttObservacao"
                  name="ttObservacao"
                  label="Observação (opcional)"
                  disabled={false}
                  renderIconShowHide={false}
                  defaultValue={ttObservacao}
                  onBlur={(valor) => setTtObservacao(valor.target.value)}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Botao
                  label="Salvar"
                  className={classes.button}
                  onClick={() => onClickSalvarFormularioTrocaTitularidade()}
                  disabled={false}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Botao
                  label="Nova troca"
                  className={classes.button}
                  onClick={() => onClickSalvarNovaTroca()}
                  disabled={btnNovaTrocaDisabled}
                />
              </Grid>
              <Grid
                item
                lg={3}
                md={3}
                sm={6}
                sx={{ display: { xs: "none", lg: "block" } }}
                className="d-none d-md-block"
              />
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Botao
                  label="Onboarding Finalizado"
                  className={classes.button}
                  onClick={() => onClickOnboardingFinalizado()}
                  disabled={btnMedidorInstaladoDisabled}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Card>
    </Loader>
  );
};

export default AcompanhamentoCliente;
