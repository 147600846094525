import React from "react";
import PropTypes from "prop-types";
import MaterialInputMascara from "../inputTextoMascara";
import Loader from "../loader";

function InputTextoCEP({
  id,
  name,
  label,
  register,
  errors,
  defaultValue,
  limparValor,
  redefineLimpar,
  carregandoEndereco,
  onBlurCep,
  disabled
}) {
  return (
    <Loader loading={carregandoEndereco}>
      <MaterialInputMascara
        type="text"
        id={id}
        name={name}
        label={label}
        mask="#####-###"
        limparValor={limparValor}
        redefineLimpar={redefineLimpar}
        renderIconShowHide={false}
        defaultValue={defaultValue?.replace("-", "")}
        maxLength={9}
        disabled={disabled}
        onBlur={(event) => onBlurCep(event.target.value?.replace(/\s/g, ""))}
        ref={register({
          required: "O campo CEP é obrigatório!",
          minLength: {
            value: 9,
            message: "Quantidade minima de 9 caracteres!"
          },
          maxLength: {
            value: 9,
            message: "Quantidade máxima de 9 caracteres!"
          },
          validate: (value) =>
            value.replace(/\s/g, "").length === 9 ||
            "Deve ser informado 9 digitos do CEP"
        })}
        errors={errors}
      />
    </Loader>
  );
}

InputTextoCEP.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  register: PropTypes.oneOfType([PropTypes.func]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  carregandoEndereco: PropTypes.bool.isRequired,
  redefineLimpar: PropTypes.PropTypes.oneOfType([PropTypes.func]),
  limparValor: PropTypes.bool,
  onBlurCep: PropTypes.oneOfType([PropTypes.func]).isRequired,
  disabled: PropTypes.bool
};

InputTextoCEP.defaultProps = {
  disabled: false,
  limparValor: false,
  redefineLimpar: () => {}
};

export default InputTextoCEP;
